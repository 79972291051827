import { AIRBNB, BOOKING, EXPEDIA, VRBO } from "./constants/commonConstants";

export const toDoublePercent = (value: number): number => {
  return Number((value * 100).toFixed(1));
};

export const toWholePercent = (value: number): number => {
  return Number((value * 100).toFixed(0));
};

export const getPercentageIncrease = (
  startValue: number | string | undefined,
  finalValue: number | string | undefined
): number => {
  if (typeof startValue === "undefined" || typeof finalValue === "undefined")
    return 0;
  const dynamic =
    startValue === 0
      ? +finalValue / 100
      : (+finalValue - +startValue) / +startValue;
  return isNumber(dynamic) && isFinite(dynamic) ? toWholePercent(dynamic) : 0;
};

export const isNumber = (value: any) => {
  return typeof value === "number" && !isNaN(value);
};

export const toPrettyNumber = (value: number | string | undefined) => {
  return isNumber(value)
    ? parseFloat(Number(value).toFixed(0)).toLocaleString("en-en")
    : 0;
};

export const range = (start: number, end: number): number[] => {
  if (start > end) return [];
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
};

export const yearsRange = (minYear = 2019) => {
  return range(minYear, new Date().getFullYear() + 1);
};

export const getCurrentYearString = () => String(new Date().getFullYear());

export const getPreviousYearString = () => String(new Date().getFullYear() - 1);

export const showLabel = (
  value: any,
  labelSingular: string,
  labelMulti: string
) => {
  if (value && Number(value)) {
    if (value > 1) {
      return `${value} ${labelMulti}`;
    }
    return `${value} ${labelSingular}`;
  }
  return "-";
};

export const getChannel = (channel: string) => {
  switch (channel) {
    case "airbnb2": {
      return AIRBNB;
    }
    case "bookingCom": {
      return BOOKING;
    }
    case "homeaway2": {
      return VRBO;
    }
    case "expedia": {
      return EXPEDIA;
    }
    default: {
      return "";
    }
  }
};

export const getFormattedCurrency = (
  rawValue: number,
  currency = "USD"
): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 0,
    currency,
  });

  const processedValue = isNumber(rawValue) ? +rawValue.toFixed(0) : 0;

  return formatter.format(processedValue).replace(/^(\D+)/, "$1 ");
};

export const findMaxNumericValue = (array: any = []) => {
  return array
    .map((month: any) => Object.values(month))
    .flat()
    .map((string: string) => Number(string))
    .filter((number: number) => !isNaN(number))
    .reduce((num: number, acc: number) => Math.max(num, acc), 0);
};
