import { call, put, takeLatest } from "redux-saga/effects";
import { IGetRevenueByYearRequest, RevenueAPI } from "../../../api/revenueAPI";
import {
  GET_YEARLY_REVENUE_ERROR,
  GET_YEARLY_REVENUE_REQUEST,
  GET_YEARLY_REVENUE_SUCCESS,
} from "../../actions";

function* workerYearlyRevenue(action: {
  type: string;
  payload: IGetRevenueByYearRequest;
}): Generator<any> {
  try {
    const revenueByYear: any = yield call(
      RevenueAPI.getYearlyRevenue,
      action.payload
    );
    yield put({
      type: GET_YEARLY_REVENUE_SUCCESS,
      payload: {
        revenueByYear: revenueByYear,
      },
    });
  } catch (error: any) {
    yield put({
      type: GET_YEARLY_REVENUE_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherYearlyRevenue() {
  yield takeLatest(GET_YEARLY_REVENUE_REQUEST, workerYearlyRevenue);
}
