import axios from "axios";

axios.defaults.baseURL = "https://myangelhost-data.com/pr-api";
axios.defaults.withCredentials = false;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";

export const getAuthHeader = () =>
  `Bearer ${sessionStorage.getItem("AH_JWT_TOKEN")}`;

export const apiInstance = () => {
  return axios.create({
    headers: {
      Authorization: getAuthHeader(),
    },
  });
};

export const apiDownloadInstance = () => {
  return axios.create({
    timeout: 1000000,
    responseType: "blob",
    headers: {
      Authorization: getAuthHeader(),
      Accept: "application/vnd.ms-excel",
    },
  });
};
