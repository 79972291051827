import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AverageDailyRate.module.scss";
import { GET_REVENUE_BY_MONTH_REQUEST } from "../../../../../redux/actions";
import {
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { revenueByMonthSelector } from "../../../../../redux/selectors/revenueSelector";
import { IYearlyRevenue } from "../../../../interfaces";
import {
  findMaxNumericValue,
  getCurrentYearString,
  getPreviousYearString,
} from "../../../../../utils/commonUtils";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { Divider, IconButton } from "@mui/material";
import { BarIcon } from "../../../../../assets/icons/BarIcon";
import { LineIcon } from "../../../../../assets/icons/LineIcon";
import { AverageDailyRateBarChart } from "./components/AverageDailyRateBarChart/AverageDailyRateBarChart";
import { AverageDailyRateLineChart } from "./components/AverageDailyRateLineChart/AverageDailyRateLineChart";
import { performanceDataFilterSelector } from "../../../../../redux/selectors/performanceDataSelector";

export const AverageDailyRateByMonth = () => {
  const dispatch = useDispatch();
  const filters = useSelector(performanceDataFilterSelector);

  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");
  const [maxRevenueValue, setMaxRevenueValue] = useState(0);
  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(revenueByMonthSelector(fromYear));
  const toYearData = useSelector(revenueByMonthSelector(toYear));
  const isMobileView = useSelector(isMobileModeSelector);

  const years = yearsList();

  const revenueBarLegend = [
    {
      label: fromYear,
      color: "#D54A0D40",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#D54A0D",
      legendType: "circle",
    },
  ];

  const data =
    fromYearData && toYearData
      ? fromYearData.map(
          (
            {
              accommodation_fare_adjusted_usd,
              Occupancy_Guest,
            }: IYearlyRevenue,
            index: number
          ) => {
            const toYearDataFees = toYearData[index];
            return {
              name: shortMonthsNames[index],
              [fromYear]: accommodation_fare_adjusted_usd / +Occupancy_Guest,
              [toYear]: toYearDataFees
                ? toYearDataFees.accommodation_fare_adjusted_usd /
                  +toYearDataFees.Occupancy_Guest
                : 0,
            };
          }
        )
      : null;

  useEffect(() => {
    if (data) {
      setMaxRevenueValue(findMaxNumericValue(data));
    }
  }, [data]);

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: filters
          ? {
              owners: filters.ownersToRequest,
              properties: filters.propertiesToRequest,
              beds: filters.bedroomsToRequest,
              years: [fromYear, toYear],
            }
          : { owners: [], properties: [], beds: [], year: [fromYear, toYear] },
      });
    }
  }, [dispatch, filters, fromYear, toYear]);

  return (
    <div className={classes.wrapper} id="avg_daily_rate">
      <div className={classes.revenueBarContainer}>
        <div className={classes.revenueBarHeader}>
          <div className={classes.revenueBarHeaderLeft}>
            <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
              Average Daily Rate:
            </h4>
            <div className={classes.periodBlock}>
              <PeriodDropdown
                period={fromYear}
                periodList={years}
                setPeriod={setFromYear}
                variant="standard"
              />
              <h4 className={classes.centerSelfAlign}> vs. </h4>
              <PeriodDropdown
                period={toYear}
                periodList={years}
                setPeriod={setToYear}
                variant="standard"
              />
            </div>
            {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
            {!isMobileView && <Legend legendData={revenueBarLegend} />}
          </div>
          {!isMobileView && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
        {isBarActive
          ? data && (
              <AverageDailyRateBarChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )
          : data && (
              <AverageDailyRateLineChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )}
        {isMobileView && (
          <div className={classes.legendRow}>
            <Legend legendData={revenueBarLegend} />

            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
