export const DownloadIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5 21.3333C24.5 22.0667 24.2387 22.6947 23.716 23.2173C23.1933 23.74 22.5658 24.0009 21.8333 24L3.16667 24C2.43334 24 1.80534 23.7387 1.28267 23.216C0.760002 22.6933 0.499113 22.0658 0.500002 21.3333L0.500002 12L3.16667 12L3.16667 21.3333L21.8333 21.3333L21.8333 12L24.5 12L24.5 21.3333Z"
      fill="#5D6293"
    />
    <path
      d="M7.7673 7.5L5.83396 9.33333L12.5006 16L19.1673 9.33333L17.234 7.5L13.834 10.9L13.834 2.33127e-07L11.1673 3.49691e-07L11.1673 10.9L7.7673 7.5Z"
      fill="#5D6293"
    />
  </svg>
);
