export const BLOCK_IDS = [
  "filters",
  "highlights",
  "occupancy",
  "vsMarket",
  "occupancy_rate",
  "revenue",
  "ownerOccupancy",
  "avg_daily_rate",
  "revpan",
];

export const MOBILE_BLOCK_IDS = [
  "filters",
  "highlights",
  "revenueByYear",
  "occupancyBlock",
  "vsMarket",
  "occupancy_rate",
  "revenue",
  "ownerOccupancy",
  "avg_daily_rate",
  "revpan",
];
