import { all, call, put, takeLatest } from "redux-saga/effects";
import { L3060D, L30D } from "../../utils/constants/filterConstants";
import { CommonAPI } from "../../api/commonAPI";
import { PerfomanceAPI } from "../../api/perfomanceAPI";
import {
  PERFOMANCE_HIGHLIGHTS_REQUEST,
  PERFOMANCE_HIGHLIGHTS_SUCCESS,
  PERFOMANCE_HIGHLIGHTS_ERROR,
} from "../actions";
import { RatingAPI } from "../../api/ratingAPI";

function* workerPerfomanceHighlights(action: {
  type: string;
  payload: {
    owners: string[];
    properties: string[];
    beds: string[];
    param: string;
  };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      call(CommonAPI.getUniqueReservations, params),
      call(CommonAPI.getTotalBookedNights, params),
      call(PerfomanceAPI.getAvarageDailyRates, params),
      call(RatingAPI.getReviews, params),
    ]);
    const [uniqueReservations, totalBookedNights, avarageDailyRates, reviews] =
      data;

    const basePayload: { [key: string]: object } = {
      uniqueReservations: uniqueReservations,
      totalBookedNights: totalBookedNights,
      avarageDailyRates: avarageDailyRates,
      reviews: reviews,
    };

    // if (params.param === L30D) {
    //   basePayload[L3060D] = {
    //     uniqueReservations: uniqueReservations?.last_30_60_count,
    //     totalBookedNights: +totalBookedNights?.last_30_60_day,
    //     avarageDailyRates: avarageDailyRates?.last_30_60_day,
    //     netReservationIncome: netReservationIncome?.last_30_60_day_total_income,
    //   };
    // }

    yield put({
      type: PERFOMANCE_HIGHLIGHTS_SUCCESS,
      payload: { ...basePayload },
    });
  } catch (error: any) {
    yield put({
      type: PERFOMANCE_HIGHLIGHTS_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherPerfomanceHighlights() {
  yield takeLatest(PERFOMANCE_HIGHLIGHTS_REQUEST, workerPerfomanceHighlights);
}
