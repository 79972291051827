import { call, put, takeLatest } from "redux-saga/effects";
import {
  PR_HEADER_ERROR,
  PR_HEADER_REQUEST,
  PR_HEADER_SUCCESS,
} from "../../actions";
import { PerformanceDataAPI } from "../../../api/performanceDataAPI";

function* workerPerformanceHeader(): Generator<any> {
  try {
    const data: any = yield call(PerformanceDataAPI.getPerformanceHeader);

    yield put({
      type: PR_HEADER_SUCCESS,
      payload: { header: data },
    });
  } catch (error: any) {
    yield put({
      type: PR_HEADER_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherPerformanceHeader() {
  yield takeLatest(PR_HEADER_REQUEST, workerPerformanceHeader);
}
