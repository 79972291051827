import { CalendarIcon } from "../../../assets/icons/CalendarIcon";
import { DownloadIcon } from "../../../assets/icons/DownloadIcon";
import { GlossaryIcon } from "../../../assets/icons/GlossaryIcon";
import { OccupancyIcon } from "../../../assets/icons/OccupancyIcon";
import { PerfomanceIcon } from "../../../assets/icons/PerfomanceIcon";
import { RatingIcon } from "../../../assets/icons/RatingIcon";
import { RevenueIcon } from "../../../assets/icons/RevenueIcon";

export const mainMenuItems = [
  {
    id: "perfomance",
    label: "Performance",
    icon: <PerfomanceIcon />,
    url: "/perfomance",
  },
  {
    id: "occupancy",
    label: "Occupancy",
    icon: <OccupancyIcon />,
    url: "/occupancy",
  },
  {
    id: "revenue",
    label: "Revenue",
    icon: <RevenueIcon />,
    url: "/revenue",
  },
  {
    id: "rating",
    label: "Rating",
    icon: <RatingIcon />,
    url: "/rating",
  },
  {
    id: "calendar",
    label: "Calendar",
    icon: <CalendarIcon />,
    url: "/calendar",
  },
];

export const additionalMenuItems = [
  // {
  //   id: "download",
  //   label: "Download",
  //   icon: <DownloadIcon />,
  //   url: "/download",
  // },
  {
    id: "glossary",
    label: "Glossary",
    icon: <GlossaryIcon />,
    url: "/download",
  },
];
