import { updateState } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import {
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  USER_DATA_SUCCESS,
  USER_LOGO_SUCCESS,
} from "../actions";

const initialState = {
  listings: null,
  property_id: null,
  roleType: null,
  userData: null,
  userLogo: null,
};

export const userReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case LOGIN_USER_SUCCESS: {
      return updateState(state, payload);
    }
    case LOGOUT_USER_SUCCESS: {
      return updateState(state, payload);
    }
    case USER_DATA_SUCCESS: {
      return updateState(state, payload);
    }
    case USER_LOGO_SUCCESS: {
      return updateState(state, payload);
    }
    default:
      return state;
  }
};
