import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { isMobileModeSelector } from "../../../../../../../redux/selectors/appStatusSelector";
import { useEffect, useRef, useState } from "react";
import classes from "./RevenuePerNightLineChart.module.scss";
import { toPrettyNumber } from "../../../../../../../utils/commonUtils";

const Background = (props: any) => {
  const { x, y, width, height, points, isMobileView } = props;
  return (
    <>
      <defs>
        <linearGradient id="g1" x1=".5" x2=".5" y2="1">
          <stop stopColor="#fcfcfd" stopOpacity="0" />
          <stop offset="1" stopColor="#5d6293" stopOpacity=".15" />
        </linearGradient>
      </defs>
      <rect
        x={points[0].x - 20}
        y={y}
        width={40}
        height={isMobileView ? height : height + 5}
        fill="url(#g1)"
        stroke="none"
        rx={12}
      />
    </>
  );
};

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    maxYAxisValue,
    isMobileView,
    rightTooltipHeight,
    cartesianGridBottomOffset,
    leftTooltipWidth,
    arrowWidth,
    leftTooltipRef,
    rightTooltipRef,
  } = props;
  const leftMonths = isMobileView ? ["Jan", "Feb", "Mar"] : ["Jan"];
  const rightMonths = isMobileView ? ["Oct", "Nov", "Dec"] : ["Nov", "Dec"];

  if (active && payload && payload.length) {
    const leftDataPoint = payload[0].payload;
    const rightDataPoint = payload[1].payload;
    const leftName = payload[0].name;
    const rightName = payload[1].name;
    const isLeftMonth = leftMonths.includes(leftDataPoint.name);
    const isRightMonth = rightMonths.includes(rightDataPoint.name);

    const dataPointDifference =
      (Math.abs(leftDataPoint[leftName] - rightDataPoint[rightName]) *
        viewBox?.height) /
      maxYAxisValue;

    const isTooltipsOnDifferentSides = dataPointDifference < rightTooltipHeight;

    return (
      <>
        <div
          className={`${classes.tooltip} ${
            !isLeftMonth || (isTooltipsOnDifferentSides && isLeftMonth)
              ? classes.leftTooltip
              : classes.rightTooltip
          }`}
          style={{
            bottom:
              (viewBox?.height * leftDataPoint[leftName]) / maxYAxisValue -
              cartesianGridBottomOffset +
              4,
            left:
              !isLeftMonth || (isTooltipsOnDifferentSides && isLeftMonth)
                ? -leftTooltipWidth - arrowWidth - 2
                : arrowWidth + 2,
          }}
          ref={leftTooltipRef}
        >
          <p>{`$${toPrettyNumber(Number(leftDataPoint[leftName]))}`}</p>
        </div>

        <div
          className={`${classes.tooltip} ${
            !isRightMonth || (isTooltipsOnDifferentSides && isRightMonth)
              ? classes.rightTooltip
              : classes.leftTooltip
          }`}
          style={{
            bottom:
              (viewBox?.height * rightDataPoint[rightName]) / maxYAxisValue -
              cartesianGridBottomOffset +
              4,
            left:
              !isRightMonth || (isTooltipsOnDifferentSides && isRightMonth)
                ? arrowWidth + 2
                : arrowWidth + 2 - leftTooltipWidth,
          }}
          ref={rightTooltipRef}
        >
          <p>{`$${toPrettyNumber(Number(rightDataPoint[rightName]))}`}</p>
        </div>
      </>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        $ {payload.value.toLocaleString("en-EN")}
      </text>
    </g>
  );
};

type AverageDailyRateLineChartProps = {
  data: object[];
  fromYear: string;
  toYear: string;
  maxRevenueValue: number;
};

export const AverageDailyRateLineChart = ({
  data,
  fromYear,
  toYear,
  maxRevenueValue,
}: AverageDailyRateLineChartProps) => {
  const isMobileView = useSelector(isMobileModeSelector);
  const arrowWidth = 6;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const yAxisRef = useRef<any>(null);
  const leftTooltipRef = useRef<any>(null);
  const rightTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [cartesianGridBottomOffset, setCartesianGridBottomOffset] = useState(0);
  const [maxYAxisValue, setMaxYAxisValue] = useState(
    Math.ceil(maxRevenueValue)
  );
  const [leftTooltipWidth, setLeftTooltipWidth] = useState(0);
  const [rightTooltipHeight, setRightTooltipHeight] = useState(0);

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const leftTooltip = leftTooltipRef?.current;
    const rightTooltip = rightTooltipRef?.current;

    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );
    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridBottomOffset(
        responsiveContainer.getBoundingClientRect().bottom -
          cartesianGrid.getBoundingClientRect().bottom
      );

    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    leftTooltip &&
      setLeftTooltipWidth(leftTooltip.getBoundingClientRect().width);
    rightTooltip &&
      setRightTooltipHeight(rightTooltip.getBoundingClientRect().height);
  }, [activeTooltipIndex]);

  useEffect(() => {
    if (maxRevenueValue) {
      setMaxYAxisValue(
        Math.ceil(maxRevenueValue - (maxRevenueValue % 10) + 10)
      );
    }
  }, [maxRevenueValue]);

  return (
    <>
      {data && (
        <ResponsiveContainer
          height="80%"
          width="100%"
          ref={responsiveContainerRef}
        >
          <LineChart
            data={data}
            onMouseMove={({ activeTooltipIndex }) =>
              setActiveTooltipIndex(activeTooltipIndex)
            }
            onMouseLeave={() => setActiveTooltipIndex(undefined)}
            margin={
              isMobileView
                ? { top: 0, left: 0, right: 16, bottom: 0 }
                : { top: 5, left: 5, right: 5, bottom: 5 }
            }
          >
            <CartesianGrid
              stroke="#B4B7CF"
              strokeDasharray="2.33 4.66"
              strokeWidth="0.5"
              vertical={false}
              ref={cartesianGridRef}
            />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              interval={isMobileView ? 0 : undefined}
              tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              ref={yAxisRef}
              domain={[0, maxYAxisValue]}
              tick={<CustomYAxisTick isMobileView={isMobileView} />}
            />
            <Tooltip
              position={{
                x: activeTooltipIndex
                  ? cartesianGridLeftOffset +
                    (activeTooltipIndex * cartesianGridWidth) /
                      (data.length - 1)
                  : cartesianGridLeftOffset,
                y: cartesianGridHeight - 11 - (isMobileView ? 4 : 0),
              }}
              content={
                <CustomTooltip
                  maxYAxisValue={maxYAxisValue}
                  isMobileView={isMobileView}
                  rightTooltipHeight={rightTooltipHeight}
                  cartesianGridBottomOffset={cartesianGridBottomOffset}
                  leftTooltipWidth={leftTooltipWidth}
                  arrowWidth={arrowWidth}
                  leftTooltipRef={leftTooltipRef}
                  rightTooltipRef={rightTooltipRef}
                />
              }
              cursor={<Background isMobileView={isMobileView} />}
            />
            <Line
              dataKey={fromYear}
              isAnimationActive={false}
              stroke="#0170A140"
              strokeWidth={3}
              type="monotone"
              filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
              dot={false}
            />
            <Line
              dataKey={toYear}
              isAnimationActive={false}
              stroke="#0170A1"
              strokeWidth={3}
              type="monotone"
              filter="drop-shadow(0px 7px 5px rgba(93, 98, 147, 0.43))"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
