import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import classes from "./FilterPopover.module.scss";
import { IPerformanceDataElement } from "../../../../../../../api/performanceDataAPI";
import { useDispatch, useSelector } from "react-redux";
import { PR_DATA_FILTER } from "../../../../../../../redux/actions";
import { performanceDataFilterSelector } from "../../../../../../../redux/selectors/performanceDataSelector";
import { CrossIcon } from "../../../../../../../assets/icons/CrossIcon";
import { SearchIcon } from "../../../../../../../assets/icons/SearchIcon";

interface IFilterPopoverProps {
  id: string;
  open: boolean;
  anchor: HTMLButtonElement;
  onClose: () => void;
  userLevel: number;
  data: any[];
}

export const FilterPopover = ({
  id,
  open,
  anchor,
  onClose,
  userLevel,
  data,
}: IFilterPopoverProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(performanceDataFilterSelector);

  const [selectedOwnersValues, setSelectedOwnersValues] = useState<string[]>(
    []
  );
  const [selectedBedroomsValues, setSelectedBedroomValues] = useState<string[]>(
    []
  );
  const [selectedPropertiesValues, setSelectedPropertiesValues] = useState<
    string[]
  >([]);
  const [checkedValues, setCheckedValues] = useState({ yes: false, no: false });

  const [searchOwnerText, setSearchOwnerText] = useState<string>("");

  useEffect(() => {
    if (data) {
      setCheckedValues({
        yes: data.some((el: IPerformanceDataElement) => !!+el.is_listed),
        no: !data.some((el: IPerformanceDataElement) => !!+el.is_listed),
      });
    }
  }, [data]);

  const handleSearchOwnerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchOwnerText(event.target.value);
  };
  const [searchPropertyText, setSearchPropertyText] = useState<string>("");

  const handleSearchPropertyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchPropertyText(event.target.value);
  };
  const [searchBedroomText, setSearchBedroomText] = useState<string>("");

  const handleSearchBedroomChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBedroomText(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckedValues({ ...checkedValues, [name]: checked });
  };

  const preparedOwners = useMemo(
    () =>
      data &&
      userLevel === 1 &&
      data
        .reduce((prev, current: IPerformanceDataElement) => {
          const current_owner = current.property_id.substring(1, 4);
          if (!prev.includes(current_owner)) {
            if (
              (!selectedBedroomsValues.length ||
                selectedBedroomsValues.includes(current.bedrooms)) &&
              (!selectedPropertiesValues.length ||
                selectedPropertiesValues.includes(current.property_id)) &&
              !!+current.is_listed
                ? checkedValues.yes
                : checkedValues.no
            ) {
              prev.push(current_owner);
            }
          }

          return prev;
        }, [])
        .filter((item: string) =>
          item.toLowerCase().includes(searchOwnerText.toLowerCase())
        ),
    [
      checkedValues.no,
      checkedValues.yes,
      data,
      searchOwnerText,
      selectedBedroomsValues,
      selectedPropertiesValues,
      userLevel,
    ]
  );

  const preparedBedrooms = useMemo(
    () =>
      data &&
      data
        .reduce((prev, current) => {
          const current_owner = current.property_id.substring(1, 4);
          if (!prev.includes(current.bedrooms)) {
            if (
              (!selectedOwnersValues.length ||
                selectedOwnersValues.includes(current_owner)) &&
              (!selectedPropertiesValues.length ||
                selectedPropertiesValues.includes(current.property_id)) &&
              !!+current.is_listed
                ? checkedValues.yes
                : checkedValues.no
            ) {
              prev.push(current.bedrooms);
            }
          }

          return prev;
        }, [])
        .filter((item: string) =>
          item.toLowerCase().includes(searchBedroomText.toLowerCase())
        )
        .sort((a: string, b: string) => +a - +b),
    [
      checkedValues.no,
      checkedValues.yes,
      data,
      searchBedroomText,
      selectedOwnersValues,
      selectedPropertiesValues,
    ]
  );

  const preparedProperties = useMemo(
    () =>
      data &&
      data
        .reduce((prev, current) => {
          const current_owner = current.property_id.substring(1, 4);
          if (!prev.includes(current.property_id)) {
            if (
              (!selectedOwnersValues.length ||
                selectedOwnersValues.includes(current_owner)) &&
              (!selectedBedroomsValues.length ||
                selectedBedroomsValues.includes(current.bedrooms)) &&
              !!+current.is_listed
                ? checkedValues.yes
                : checkedValues.no
            ) {
              prev.push(current.property_id);
            }
          }

          return prev;
        }, [])
        .filter((item: string) =>
          item.toLowerCase().includes(searchPropertyText.toLowerCase())
        )
        .sort((a: string, b: string) => {
          const current_owner_a = a.substring(1, 4);
          const current_owner_b = b.substring(1, 4);

          if (current_owner_a > current_owner_b) {
            return 1;
          } else if (current_owner_a < current_owner_b) {
            return -1;
          }

          return 0;
        }),
    [
      checkedValues.no,
      checkedValues.yes,
      data,
      searchPropertyText,
      selectedBedroomsValues,
      selectedOwnersValues,
    ]
  );

  const handleOwnersChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (value.includes("selectAll")) {
      setSelectedOwnersValues(value[0] === "selectAll" ? preparedOwners : []);
    } else {
      setSelectedOwnersValues(value as string[]);
    }
  };

  const handleBedroomsChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (value.includes("selectAll")) {
      setSelectedBedroomValues(
        value[0] === "selectAll" ? preparedBedrooms : []
      );
    } else {
      setSelectedBedroomValues(event.target.value as string[]);
    }
  };
  const handlePropertiesChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (value.includes("selectAll")) {
      setSelectedPropertiesValues(
        value[0] === "selectAll" ? preparedProperties : []
      );
    } else {
      setSelectedPropertiesValues(event.target.value as string[]);
    }
  };

  const handleOnlyThisOwnersChange = (type: string, value: string) => {
    switch (type) {
      case "properties":
        return setSelectedPropertiesValues([value]);
      case "bedrooms":
        return setSelectedBedroomValues([value]);
      default:
        return setSelectedOwnersValues([value]);
    }
  };

  const handlePropertyRemove = (property: string) =>
    setSelectedPropertiesValues((prev) => prev.filter((el) => el !== property));
  const handleBedroomRemove = (bedroom: string) =>
    setSelectedBedroomValues((prev) => prev.filter((el) => el !== bedroom));
  const handleOwnerRemove = (owner: string) =>
    setSelectedOwnersValues((prev) => prev.filter((el) => el !== owner));

  const handleApply = useCallback(() => {
    dispatch({
      type: PR_DATA_FILTER,
      payload: {
        filters: {
          owners: selectedOwnersValues,
          bedrooms: selectedBedroomsValues,
          properties: selectedPropertiesValues,
          listed: checkedValues,
          ownersToRequest: selectedOwnersValues.length
            ? selectedOwnersValues
            : selectedBedroomsValues.length || selectedPropertiesValues.length
            ? preparedOwners
            : [],
          propertiesToRequest: selectedPropertiesValues.length
            ? selectedPropertiesValues
            : selectedBedroomsValues.length || selectedOwnersValues.length
            ? preparedProperties
            : [],
          bedroomsToRequest: selectedBedroomsValues.length
            ? selectedBedroomsValues
            : selectedPropertiesValues.length || selectedOwnersValues.length
            ? preparedBedrooms
            : [],
        },
      },
    });
    onClose();
  }, [
    dispatch,
    selectedOwnersValues,
    selectedBedroomsValues,
    selectedPropertiesValues,
    checkedValues,
    preparedOwners,
    preparedProperties,
    preparedBedrooms,
    onClose,
  ]);

  const handleReset = useCallback(() => {
    dispatch({
      type: PR_DATA_FILTER,
      payload: {
        filters: null,
      },
    });
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => {
    if (filters) {
      setSelectedOwnersValues(filters.owners);
      setSelectedBedroomValues(filters.bedrooms);
      setSelectedPropertiesValues(filters.properties);
      setCheckedValues(filters.listed);
    }
  }, [filters]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      id={id}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      PaperProps={{
        style: {
          boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
          borderRadius: "24px",
        },
      }}
    >
      <Box className={classes.popoverContentWrapper}>
        <h3
          style={{
            marginBottom: "0px",
            fontSize: "24px",
            color: "#02094F",
            margin: 0,
          }}
        >
          Filter options
        </h3>
        <Box width="100%">
          <FormControl fullWidth>
            <p className={classes.selectCheckboxLabel}>Listed</p>
            <Box display="flex">
              {data.some((el: IPerformanceDataElement) => !!+el.is_listed) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedValues.yes}
                      onChange={handleChange}
                      name="yes"
                    />
                  }
                  label="Yes"
                />
              )}
              {data.some((el: IPerformanceDataElement) => !+el.is_listed) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedValues.no}
                      onChange={handleChange}
                      name="no"
                    />
                  }
                  label="No"
                />
              )}
            </Box>
          </FormControl>
          {userLevel === 1 && (
            <FormControl fullWidth>
              <p className={classes.selectLabel}>Owner</p>
              <Select
                fullWidth
                multiple
                displayEmpty
                value={selectedOwnersValues}
                onChange={handleOwnersChange}
                sx={{
                  height: "auto",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
                SelectDisplayProps={{
                  style: {
                    padding: 0,
                  },
                }}
                renderValue={(selected) =>
                  selected.length ? (
                    <Box display="flex" flexWrap="wrap" gap="4px">
                      {selected.slice(0, 3).map((option) => (
                        <Box
                          key={option}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOwnerRemove(option);
                          }}
                        >
                          <Chip
                            label={
                              <Box
                                sx={{
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                {option} <CrossIcon />
                              </Box>
                            }
                            sx={{
                              height: "24px",
                              borderRadius: "8px",
                              padding: "2px 8px 2px 8px",
                              fontSize: "14px",
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                          />
                        </Box>
                      ))}
                      {selected.length > 3 && (
                        <Chip
                          label={
                            <Box
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              More...
                            </Box>
                          }
                          sx={{
                            height: "24px",
                            borderRadius: "8px",
                            padding: "2px 8px 2px 8px",
                            fontSize: "14px",
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      )}
                    </Box>
                  ) : (
                    "Select option"
                  )
                }
                classes={{
                  outlined: classes.outlinedSelect,
                  standard: classes.standardSelect,
                }}
                MenuProps={{
                  classes: { paper: classes.selectMenu },
                }}
              >
                <MenuItem>
                  <TextField
                    fullWidth
                    value={searchOwnerText}
                    onChange={handleSearchOwnerChange}
                    placeholder="Select option"
                    variant="outlined"
                    margin="normal"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box mr={-2} mt={1}>
                          <SearchIcon />
                        </Box>
                      ),
                      style: {
                        height: "40px",
                        fontSize: "14px",
                      },
                    }}
                    sx={{
                      borderRadius: "8px",
                    }}
                  />
                </MenuItem>
                <MenuItem value="selectAll">
                  <Checkbox
                    checked={
                      selectedOwnersValues.length === preparedOwners.length
                    }
                  />
                  Select all
                </MenuItem>
                {preparedOwners.map((item: string) => (
                  <MenuItem
                    key={item}
                    value={item}
                    className={classes.onlyThisHiddenItem}
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Checkbox
                          checked={selectedOwnersValues.includes(item)}
                        />
                        {item}
                      </Box>

                      <Typography
                        className={classes.onlyThis}
                        onClick={(e) => {
                          handleOnlyThisOwnersChange("owners", item);
                          e.stopPropagation();
                        }}
                      >
                        Only this
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl fullWidth>
            <p className={classes.selectLabel}>Property</p>
            <Select
              fullWidth
              multiple
              displayEmpty
              value={selectedPropertiesValues}
              onChange={handlePropertiesChange}
              sx={{
                height: "auto",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                },
              }}
              renderValue={(selected) =>
                selected.length ? (
                  <Box display="flex" flexWrap="wrap" gap="4px">
                    {selected.slice(0, 3).map((option) => (
                      <Box
                        key={option}
                        onClick={(e) => {
                          handlePropertyRemove(option);
                          e.stopPropagation();
                        }}
                      >
                        <Chip
                          label={
                            <Box
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {option} <CrossIcon />
                            </Box>
                          }
                          sx={{
                            height: "24px",
                            borderRadius: "8px",
                            padding: "2px 8px 2px 8px",
                            fontSize: "14px",
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      </Box>
                    ))}
                    {selected.length > 3 && (
                      <Chip
                        label={
                          <Box
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            More...
                          </Box>
                        }
                        sx={{
                          height: "24px",
                          borderRadius: "8px",
                          padding: "2px 8px 2px 8px",
                          fontSize: "14px",
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    )}
                  </Box>
                ) : (
                  "Select option"
                )
              }
              classes={{
                outlined: classes.outlinedSelect,
                standard: classes.standardSelect,
              }}
              MenuProps={{
                classes: { paper: classes.selectMenu },
              }}
            >
              <MenuItem>
                <TextField
                  fullWidth
                  value={searchPropertyText}
                  onChange={handleSearchPropertyChange}
                  placeholder="Select option"
                  variant="outlined"
                  margin="normal"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  InputProps={{
                    startAdornment: (
                      <Box mr={-2} mt={1}>
                        <SearchIcon />
                      </Box>
                    ),
                    style: {
                      height: "40px",
                      fontSize: "14px",
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                  }}
                />
              </MenuItem>
              <MenuItem value="selectAll">
                <Checkbox
                  checked={
                    selectedPropertiesValues.length ===
                    preparedProperties.length
                  }
                />
                Select all
              </MenuItem>
              {preparedProperties.map((item: string) => (
                <MenuItem
                  key={item}
                  value={item}
                  className={classes.onlyThisHiddenItem}
                >
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Checkbox
                        checked={selectedPropertiesValues.includes(item)}
                      />
                      {item}
                    </Box>

                    <Typography
                      className={classes.onlyThis}
                      onClick={(e) => {
                        handleOnlyThisOwnersChange("properties", item);
                        e.stopPropagation();
                      }}
                    >
                      Only this
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <p className={classes.selectLabel}>Bedrooms</p>
            <Select
              fullWidth
              multiple
              displayEmpty
              value={selectedBedroomsValues}
              sx={{
                height: "auto",
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
              SelectDisplayProps={{
                style: {
                  padding: 0,
                },
              }}
              onChange={handleBedroomsChange}
              renderValue={(selected) =>
                selected.length ? (
                  <Box display="flex" flexWrap="wrap" gap="4px">
                    {selected.slice(0, 3).map((option) => (
                      <Box
                        key={option}
                        onClick={() => handleBedroomRemove(option)}
                      >
                        <Chip
                          label={
                            <Box
                              sx={{
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              {option} <CrossIcon />
                            </Box>
                          }
                          sx={{
                            height: "24px",
                            borderRadius: "8px",
                            padding: "2px 8px 2px 8px",
                            fontSize: "14px",
                          }}
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      </Box>
                    ))}
                    {selected.length > 3 && (
                      <Chip
                        label={
                          <Box
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            More...
                          </Box>
                        }
                        sx={{
                          height: "24px",
                          borderRadius: "8px",
                          padding: "2px 8px 2px 8px",
                          fontSize: "14px",
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    )}
                  </Box>
                ) : (
                  "Select option"
                )
              }
              classes={{
                outlined: classes.outlinedSelect,
                standard: classes.standardSelect,
              }}
              MenuProps={{
                classes: { paper: classes.selectMenu },
              }}
            >
              <MenuItem>
                <TextField
                  fullWidth
                  value={searchBedroomText}
                  onChange={handleSearchBedroomChange}
                  variant="outlined"
                  placeholder="Select option"
                  margin="none"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  InputProps={{
                    startAdornment: (
                      <Box mr={-2} mt={1}>
                        <SearchIcon />
                      </Box>
                    ),
                    style: {
                      height: "40px",
                      fontSize: "14px",
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                  }}
                />
              </MenuItem>
              <MenuItem value="selectAll">
                <Checkbox
                  checked={
                    selectedBedroomsValues.length === preparedBedrooms.length
                  }
                />
                Select all
              </MenuItem>
              {preparedBedrooms.map((item: string) => (
                <MenuItem
                  key={item}
                  value={item}
                  className={classes.onlyThisHiddenItem}
                >
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Checkbox
                        checked={selectedBedroomsValues.includes(item)}
                      />
                      {item}
                    </Box>

                    <Typography
                      className={classes.onlyThis}
                      onClick={(e) => {
                        handleOnlyThisOwnersChange("properties", item);
                        e.stopPropagation();
                      }}
                    >
                      Only this
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" gap="16px">
          <Button
            variant="outlined"
            className={`${classes.configureButton} ${classes.resetButton}`}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            className={classes.configureButton}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};
