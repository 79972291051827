import {
  createErrorSelector,
  createLoadingSelector,
} from "../../utils/reduxUtils";
import { PERFOMANCE_HIGHLIGHTS } from "../actions";

export const perfomanceHighlightsSelector = (state: any) =>
  state.perfomanceHighlights;

export const perfomanceHighlightsLoadingSelector = createLoadingSelector([
  PERFOMANCE_HIGHLIGHTS,
]);
export const perfomanceHighlightsErrorSelector = createErrorSelector([
  PERFOMANCE_HIGHLIGHTS,
]);
