import { getServiceURL, requestHandler } from "../utils/apiUtils";

export class RatingAPI {
  static get getReviewsServiceName() {
    return "GET_REVIEW_DATA";
  }

  static get getAvarageStayServiceName() {
    return "GET_AVERAGE_LENGTH_OF_STAY";
  }

  static get getAverageBookingWindowServiceName() {
    return "GET_AVERAGE_BOOKING_WINDOW";
  }

  static async getReviews(params?: {
    owners: string[];
    properties: string[];
    beds: string[];
    param: string;
  }): Promise<object> {
    const url = getServiceURL(RatingAPI.getReviewsServiceName);
    const response = await requestHandler(url, {
      ...params,
      properties: params?.properties.map((el) => el.split("-")[0]),
    });
    return response?.data?.data;
  }

  static async getAverageStay(params?: { param?: string }): Promise<object> {
    const url = getServiceURL(RatingAPI.getAvarageStayServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }

  static async getAverageBookingWindow(params?: {
    param?: string;
  }): Promise<object> {
    const url = getServiceURL(RatingAPI.getAverageBookingWindowServiceName);
    const response = await requestHandler(url, params);
    return response?.data;
  }
}
