import React, { useState } from "react";

import classes from "./FiltersContainer.module.scss";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import { DownloadIcon } from "../../../../../assets/icons/DownloadIcon";
import { FiltersIcon } from "../../../../../assets/icons/FiltersIcon";
import { UsdIcon } from "../../../../../assets/icons/UsdIcon";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { FilterPopover } from "./components/FilterPopover/FilterPopover";
import { RevenuePopover } from "./components/RevenuePopover/RevenuePopover";
import { RevenuePopoverModal } from "./components/RevenuePopover/RevenuePopoverModal";
import { FilterPopoverModal } from "./components/FilterPopover/FilterPopoverModal";
import {
  performanceDataFilterSelector,
  performanceDataSelector,
  performanceHeaderSelector,
  performanceRevenueFilterSelector,
} from "../../../../../redux/selectors/performanceDataSelector";
import { FiltersFilledIcon } from "../../../../../assets/icons/FiltersFilledIcon";
import { IPerformanceHeader } from "../../../../../api/performanceDataAPI";

import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Document, Page, Image, pdf } from "@react-pdf/renderer";
import { BLOCK_IDS } from "../../../../../utils/constants/pdfGeneratorConstants";
import { UsdFilltedIcon } from "../../../../../assets/icons/UsdFilledIcon";

interface IFiltersContainer {
  count: number | null;
}

export const FiltersContainer = ({ count }: IFiltersContainer) => {
  const isMobileView = useSelector(isMobileModeSelector);

  const userLevel = +(sessionStorage.getItem("ROLE_TYPE") || 1);
  const filters = useSelector(performanceDataFilterSelector);
  const revenue_filters = useSelector(performanceRevenueFilterSelector);

  const header: IPerformanceHeader = useSelector(performanceHeaderSelector);

  const isAdminView = userLevel < 3;

  const prData = useSelector(performanceDataSelector);

  const [anchorFiltersEl, setAnchorFiltersEl] =
    useState<HTMLButtonElement | null>(null);
  const [anchorRevenueEl, setAnchorRevenueEl] =
    useState<HTMLButtonElement | null>(null);

  const handleFiltersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFiltersEl(event.currentTarget);
  };

  const handleFiltersClose = () => {
    setAnchorFiltersEl(null);
  };

  const isFiltersOpen = Boolean(anchorFiltersEl);
  const filters_id = isFiltersOpen ? "filters-popover" : undefined;

  const handleRevenueClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorRevenueEl(event.currentTarget);
  };

  const handleRevenueClose = () => {
    setAnchorRevenueEl(null);
  };

  const isRevenueOpen = Boolean(anchorRevenueEl);
  const revenue_id = isRevenueOpen ? "revenue-popover" : undefined;

  const handleGeneratePDF = () => {
    const promises = BLOCK_IDS.map((id) => {
      const input = document.getElementById(id);

      if (input) {
        const inputHeight = input.scrollHeight;
        const inputWidth = input.scrollWidth;

        return html2canvas(input, {
          width: inputWidth,
          windowHeight: inputHeight, // Установка высоты окна для захвата всей страницы
          scrollX: 0,
          scrollY: 0,
        });
      }

      return null;
    });

    Promise.all(promises).then((results) => {
      const pdfDoc = (
        <Document>
          <Page size="A4">
            {results.map((result) => (
              <Image src={result?.toDataURL("image/png")} />
            ))}
          </Page>
        </Document>
      );

      const asPdfBlob = pdf(pdfDoc).toBlob();

      asPdfBlob.then((blob: string | Blob) => {
        saveAs(blob, "performance_report.pdf");
      });
    });
  };

  return (
    <Box className={classes.filtersContrainerWrapper} id="filters">
      <Box
        display="flex"
        flexDirection="column"
        gap={isMobileView ? "16px" : "16px"}
      >
        <Box display="flex" gap="24px">
          <Box>
            <h2 className={classes.headerText}>Performance report</h2>
            <Typography>Updated {header && header.updated_at}</Typography>
          </Box>
          {isAdminView && !isMobileView && (
            <IconButton
              color="primary"
              className={classes.downloadIcon}
              onClick={handleGeneratePDF}
            >
              <DownloadIcon />
            </IconButton>
          )}
          {!isAdminView && !isMobileView && (
            <Box display="flex" gap="8px" alignItems="center">
              <Typography className={classes.liveProperties}>
                Live properties
              </Typography>
              {count && (
                <Typography className={classes.livePropertiesCount}>
                  {count.toLocaleString("en-EN")}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {(isMobileView || (!isMobileView && isAdminView)) && (
          <Box display="flex" gap="8px" alignItems="center">
            <Typography className={classes.liveProperties}>
              Live properties
            </Typography>
            {prData && count && (
              <Typography className={classes.livePropertiesCount}>
                {count.toLocaleString("en-EN")}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {(isAdminView || !isMobileView) && (
        <Box
          display="flex"
          flexDirection={isMobileView ? "column" : "row"}
          gap="24px"
        >
          <Button
            variant="outlined"
            className={classes.outlinedRevenueButton}
            onClick={isAdminView ? handleRevenueClick : handleGeneratePDF}
          >
            {isAdminView ? (
              revenue_filters ? (
                <UsdFilltedIcon />
              ) : (
                <UsdIcon />
              )
            ) : (
              <DownloadIcon />
            )}
          </Button>
          {isAdminView && (
            <Button
              variant="outlined"
              className={`${classes.filtersButton} ${classes.responsiveButton}`}
              onClick={handleFiltersClick}
            >
              <Box display="flex" gap="10px" alignItems="center">
                {filters ? <FiltersFilledIcon /> : <FiltersIcon />}
                {!isMobileView && (
                  <Typography variant="body1" className={classes.filterLabel}>
                    Filter
                  </Typography>
                )}
              </Box>
            </Button>
          )}
          {!isMobileView && (
            <Link href={header && header.glossary_link}>
              <Button variant="contained" className={classes.responsiveButton}>
                Glossary
              </Button>
            </Link>
          )}
          {anchorFiltersEl &&
            filters_id &&
            (isMobileView
              ? prData && (
                  <FilterPopoverModal
                    id={filters_id}
                    open={isFiltersOpen}
                    anchor={anchorFiltersEl}
                    onClose={handleFiltersClose}
                    userLevel={userLevel}
                    data={prData}
                  />
                )
              : prData && (
                  <FilterPopover
                    id={filters_id}
                    open={isFiltersOpen}
                    anchor={anchorFiltersEl}
                    onClose={handleFiltersClose}
                    userLevel={userLevel}
                    data={prData}
                  />
                ))}
          {anchorRevenueEl &&
            revenue_id &&
            (isMobileView ? (
              <RevenuePopoverModal
                id={revenue_id}
                open={isRevenueOpen}
                anchor={anchorRevenueEl}
                onClose={handleRevenueClose}
              />
            ) : (
              <RevenuePopover
                id={revenue_id}
                open={isRevenueOpen}
                anchor={anchorRevenueEl}
                onClose={handleRevenueClose}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};
