import { getPercentageIncrease } from "../../../utils/commonUtils";
import { DownIcon } from "../../../assets/icons/DownIcon";
import { UpIcon } from "../../../assets/icons/UpIcon";
import classes from "./DynamicIndicator.module.scss";

interface IDynamicIndicator {
  previous?: number | string;
  current?: number | string;
  isReversed?: boolean;
}

export const DynamicIndicator = ({
  previous,
  current,
  isReversed = false,
}: IDynamicIndicator) => {
  const percentage = getPercentageIncrease(previous, current);
  const isPositive = percentage >= 0;

  if (percentage === 0) return null;

  return (
    <div
      className={`${classes.container} ${isReversed ? classes.reversed : ""}`}
    >
      <div className={classes.imageContainer}>
        {isPositive ? <UpIcon /> : <DownIcon />}
      </div>
      {isPositive ? (
        <span>{`+${percentage}%`}</span>
      ) : (
        <span>{`${percentage}%`}</span>
      )}
    </div>
  );
};
