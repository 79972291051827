import { call, put, takeLatest } from "redux-saga/effects";
import { RatingAPI } from "../../../api/ratingAPI";
import {
  GET_REVIEWS_ERROR,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
} from "../../actions";

function* workerRatingData(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const rating: any = yield call(RatingAPI.getReviews);

    yield put({
      type: GET_REVIEWS_SUCCESS,
      payload: {
        [params.param]: rating,
      },
    });
  } catch (error: any) {
    yield put({
      type: GET_REVIEWS_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherRatingData() {
  yield takeLatest(GET_REVIEWS_REQUEST, workerRatingData);
}
