import { call, put, takeLatest } from "redux-saga/effects";
import { PR_DATA_ERROR, PR_DATA_REQUEST, PR_DATA_SUCCESS } from "../../actions";
import { PerformanceDataAPI } from "../../../api/performanceDataAPI";

function* workerPerformanceData(): Generator<any> {
  try {
    const data: any = yield call(PerformanceDataAPI.getPerformanceData);

    yield put({
      type: PR_DATA_SUCCESS,
      payload: { data: data.data },
    });
  } catch (error: any) {
    yield put({
      type: PR_DATA_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherPerformanceData() {
  yield takeLatest(PR_DATA_REQUEST, workerPerformanceData);
}
