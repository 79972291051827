import { updateState } from "../../utils/reduxUtils";
import { IReduxAction } from "../../views/interfaces";
import {
  PR_DATA_FILTER,
  PR_DATA_SUCCESS,
  PR_HEADER_SUCCESS,
  PR_REVENUE_FILTER,
} from "../actions";

const initialState = {
  data: null,
  filters: null,
  revenue_filters: null,
  header: null,
};

export const performanceDataReducer = (
  state = initialState,
  { type, payload }: IReduxAction
) => {
  switch (type) {
    case PR_DATA_SUCCESS: {
      return updateState(state, payload);
    }
    case PR_DATA_FILTER: {
      return updateState(state, payload);
    }
    case PR_REVENUE_FILTER: {
      return updateState(state, payload);
    }
    case PR_HEADER_SUCCESS: {
      return updateState(state, payload);
    }
    default:
      return state;
  }
};
