import { requestHandler, getServiceURL } from "../utils/apiUtils";

export interface IPerformanceDataElement {
  property_id: string;
  owner_code: string;
  city_code: string;
  bedrooms: string;
  is_listed: string;
  revenue_usd: number;
  market_or_l30: number;
  market_or_0_30: number;
  l30_or: number;
  actual_or_0_30: number;
  MINDAT: string;
}

export interface IPerformanceHeader {
  updated_at: string;
  glossary_link: string;
}

export class PerformanceDataAPI {
  static get performanceDataServiceName() {
    return "GET_PR_DATA";
  }

  static get performanceHeader() {
    return "GET_PR_HEADER";
  }

  static async getPerformanceData(): Promise<{
    data: IPerformanceDataElement[];
  }> {
    const url = getServiceURL(PerformanceDataAPI.performanceDataServiceName);
    const response = await requestHandler(url);
    return response?.data;
  }

  static async getPerformanceHeader(): Promise<IPerformanceHeader> {
    const url = getServiceURL(PerformanceDataAPI.performanceHeader);
    const response = await requestHandler(url);
    return response?.data?.data;
  }
}
