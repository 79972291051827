import { useRef, useState } from "react";
import { IconButton } from "@mui/material";

import useClickOutside from "../../../hooks/useClickOutside";
import { Logo } from "../Logo/Logo";
import classes from "./MobileHeader.module.scss";
import { additionalMenuItems } from "../MenuItems/MenuItems";
import { IMobileHeader } from "../../interfaces";
import { MenuList } from "../MenuItems/MenuList";
import { MenuIcon } from "../../../assets/icons/MenuIcon";
import { AngelHostLogoMobile } from "../../../assets/icons/AngelHostLogoMobile";

export const MobileHeader = ({
  activePath,
  listing,
  navigate,
  userName,
}: IMobileHeader) => {
  const [openMenu, setOpenMenu] = useState(false);

  const menuRef = useRef<any>(null);

  useClickOutside(menuRef, openMenu, () => setOpenMenu(false));

  return (
    <>
      <div className={classes.mobileHeaderContainer} ref={menuRef}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpenMenu(!openMenu)}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <AngelHostLogoMobile />
      </div>
      <div className={openMenu ? classes.mobileMenuContainer : classes.hidden}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpenMenu(!openMenu)}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.userData}>
          <p className={classes.userName}>{userName}</p>
          <p className={classes.userListing}>{listing}</p>
        </div>
        <MenuList
          activePath={activePath}
          list={additionalMenuItems}
          navigate={navigate}
        />
      </div>
    </>
  );
};
