export const AngelHostLogo = () => (
  <svg
    width="212"
    height="28"
    viewBox="0 0 212 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6829_61977)">
      <path
        d="M9.3424 0.210938H11.5099L20.8646 21.4014H18.3283L15.9234 15.84H4.80358L2.36517 21.4014H0L9.3424 0.210938ZM15.0523 13.7086L10.4111 2.98125L5.70993 13.7086H15.0523Z"
        fill="#02094F"
      />
      <path
        d="M27.8281 0.365234H29.9956L42.9193 17.2224V0.365234H45.1759V21.4037H43.3226L30.0847 4.151V21.4037H27.8281V0.365234Z"
        fill="#02094F"
      />
      <path
        d="M52.9648 10.9492V10.8885C52.9648 5.1126 57.1012 0.00910667 63.2568 0.00910667C66.0623 -0.107124 68.8031 0.892896 70.9066 2.80024L69.4416 4.61031C67.7513 2.99438 65.5065 2.12962 63.1968 2.20472C58.6545 2.20472 55.3988 6.14253 55.3988 10.8315V10.8922C55.3988 15.9061 58.5362 19.6303 63.5181 19.6303C65.6701 19.6275 67.7597 18.8882 69.4557 17.5297V12.2995H63.1977V10.1681H71.6594V18.5515C69.3912 20.6144 66.4667 21.7572 63.4351 21.7653C56.983 21.7653 52.9648 16.9577 52.9648 10.9492Z"
        fill="#02094F"
      />
      <path
        d="M79.8145 0.365234H94.6409V2.53732H82.1302V9.71245H93.3233V11.8746H82.1302V19.2425H94.7909V21.4046H79.8145V0.365234Z"
        fill="#02094F"
      />
      <path
        d="M102.367 0.365234H104.683V19.2117H116.232V21.4037H102.367V0.365234Z"
        fill="#02094F"
      />
      <path
        d="M123.438 0.365234H125.753V9.71245H137.59V0.365234H139.905V21.4037H137.59V11.9352H125.753V21.4037H123.438V0.365234Z"
        fill="#02094F"
      />
      <path
        d="M174.314 18.3371L175.749 16.5913C176.667 17.5457 177.764 18.3008 178.973 18.8117C180.181 19.3226 181.478 19.5789 182.785 19.5653C185.536 19.5653 187.358 18.0629 187.358 15.9931V15.9316C187.358 13.9731 186.369 12.887 182.024 11.9331C177.303 10.9186 175.096 9.3248 175.096 5.84403V5.78339C175.096 2.48544 177.936 0.049088 181.836 0.049088C184.467 -0.0250279 187.034 0.891925 189.05 2.62663L187.695 4.46385C186.051 2.98933 183.941 2.1778 181.757 2.18045C179.096 2.18045 177.393 3.68281 177.393 5.58067V5.6413C177.393 7.62062 178.443 8.68584 182.965 9.70039C187.487 10.7149 189.646 12.4399 189.646 15.6474V15.7053C189.646 19.3182 186.677 21.6622 182.64 21.6622C179.525 21.6965 176.519 20.4883 174.256 18.2928"
        fill="#02094F"
      />
      <path
        d="M202.767 2.56719H195.883V0.365234H212.004V2.56719H205.12V21.4037H202.774L202.767 2.56719Z"
        fill="#02094F"
      />
      <path
        d="M166.18 13.2812C165.834 14.2705 165.368 15.2115 164.794 16.0823C164.289 16.9041 163.24 18.4879 161.607 20.9035L158.332 25.7137H158.183C157.333 24.4349 156.204 22.842 154.907 20.9035C153.265 18.4879 152.225 16.905 151.711 16.0823C151.14 15.2188 150.672 14.2888 150.316 13.312C150.181 12.8592 150.092 12.3933 150.051 11.9219H147.852C147.903 12.6294 148.036 13.3281 148.248 14.0035C148.647 15.1671 149.193 16.2724 149.871 17.2915C150.395 18.134 151.464 19.7351 153.126 22.1931C154.445 24.1239 155.531 25.7207 156.382 26.9835C156.59 27.2986 156.87 27.5561 157.198 27.7332C157.526 27.9102 157.892 28.0012 158.263 27.998C158.632 28.0021 158.997 27.9115 159.323 27.7343C159.65 27.5571 159.928 27.299 160.133 26.9835L163.398 22.1868C165.051 19.7432 166.12 18.1277 166.644 17.305C167.313 16.2754 167.861 15.1684 168.277 14.0071C168.483 13.3307 168.612 12.6322 168.662 11.9255H166.475C166.424 12.3965 166.332 12.8618 166.198 13.3156"
        fill="#00B48D"
      />
      <path
        d="M151.078 6.57196C151.814 5.29033 152.856 4.22178 154.106 3.46678C155.359 2.71616 156.785 2.32061 158.237 2.32061C159.689 2.32061 161.115 2.71616 162.369 3.46678C163.617 4.22407 164.658 5.29211 165.397 6.57196C165.917 7.51096 166.253 8.54522 166.386 9.6165H168.573C168.419 8.14369 167.96 6.72157 167.227 5.44519C166.304 3.8355 164.996 2.49408 163.427 1.54811C161.836 0.598138 160.028 0.0976563 158.187 0.0976562C156.346 0.0976562 154.538 0.598138 152.947 1.54811C151.379 2.49665 150.072 3.83748 149.147 5.44519C148.412 6.72086 147.953 8.14333 147.801 9.6165H149.997C150.119 8.54267 150.456 7.50621 150.987 6.57196"
        fill="#02094F"
      />
      <path
        d="M162.557 9.60916C162.297 8.63798 161.734 7.78104 160.954 7.17021C160.173 6.55939 159.218 6.22852 158.237 6.22852C157.255 6.22852 156.3 6.55939 155.52 7.17021C154.739 7.78104 154.176 8.63798 153.917 9.60916C153.81 9.99903 153.753 10.4016 153.748 10.8065C153.75 11.1724 153.794 11.5368 153.877 11.8926C154.12 12.8899 154.68 13.7753 155.47 14.408C156.26 15.0407 157.234 15.3844 158.236 15.3844C159.238 15.3844 160.212 15.0407 161.002 14.408C161.792 13.7753 162.353 12.8899 162.595 11.8926C162.674 11.536 162.717 11.1721 162.724 10.8065C162.719 10.4016 162.663 9.99903 162.556 9.60916M159.845 12.4827C159.633 12.702 159.38 12.8763 159.102 12.9955C158.824 13.1147 158.525 13.1765 158.224 13.1773C157.922 13.1782 157.624 13.118 157.345 13.0004C157.066 12.8827 156.812 12.7099 156.599 12.4917L156.59 12.4827C156.425 12.3113 156.285 12.1166 156.174 11.9043C155.995 11.5496 155.906 11.1541 155.917 10.7548C155.927 10.3555 156.036 9.96538 156.233 9.62093C156.337 9.45127 156.46 9.29456 156.599 9.15393C156.806 8.9257 157.058 8.7452 157.337 8.62441C157.617 8.50363 157.919 8.44534 158.222 8.45343C158.526 8.44718 158.827 8.5063 159.106 8.62697C159.386 8.74764 159.638 8.92717 159.845 9.15393C159.978 9.29506 160.094 9.45181 160.191 9.62093C160.412 9.97889 160.525 10.3951 160.518 10.8183C160.522 11.1967 160.433 11.5701 160.26 11.9043C160.147 12.1183 160.008 12.3161 159.845 12.4926"
        fill="#00B48D"
      />
    </g>
    <defs>
      <clipPath id="clip0_6829_61977">
        <rect width="212" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
