import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./RevenueByMonth.module.scss";
import { GET_REVENUE_BY_MONTH_REQUEST } from "../../../../../redux/actions";
import {
  shortMonthsNames,
  yearsList,
} from "../../../../../utils/constants/periodLists";
import { Legend } from "../../../../common/ChartLegend/ChartLegend";
import { PeriodDropdown } from "../../../../common/PeriodDropdown/PeriodDropdown";
import { revenueByMonthSelector } from "../../../../../redux/selectors/revenueSelector";
import { IYearlyRevenue } from "../../../../interfaces";
import {
  findMaxNumericValue,
  getCurrentYearString,
  getPreviousYearString,
} from "../../../../../utils/commonUtils";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { Divider, IconButton } from "@mui/material";
import { BarIcon } from "../../../../../assets/icons/BarIcon";
import { LineIcon } from "../../../../../assets/icons/LineIcon";
import { RevenueByMonthBarChart } from "./components/RevenueByMonthBarChart/RevenueByMonthBarChart";
import { RevenueByMonthLineChart } from "./components/RevenueByMonthLineChart/RevenueByMonthLineChart";
import {
  performanceDataFilterSelector,
  performanceRevenueFilterSelector,
} from "../../../../../redux/selectors/performanceDataSelector";
import { CheckboxRevenueOption } from "../../../PerfomancePage/components/FiltersContainer/components/RevenuePopover/RevenuePopover";

export const RevenueByMonth = () => {
  const dispatch = useDispatch();
  const filters = useSelector(performanceDataFilterSelector);
  const revenue_filters = useSelector(performanceRevenueFilterSelector);

  const isAdmin = +(sessionStorage.getItem("ROLE_TYPE") || 1) === 1;

  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");
  const [maxRevenueValue, setMaxRevenueValue] = useState(0);
  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(revenueByMonthSelector(fromYear));
  const toYearData = useSelector(revenueByMonthSelector(toYear));
  const isMobileView = useSelector(isMobileModeSelector);

  const years = yearsList();

  const revenueBarLegend = [
    {
      label: fromYear,
      color: "#BDE0D9",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#009978",
      legendType: "circle",
    },
  ];

  const data =
    fromYearData && toYearData
      ? fromYearData.map(
          (
            {
              accommodation_fare_adjusted_usd,
              total_fee_usd,
              host_channel_fee_usd,
              Revenue,
            }: IYearlyRevenue,
            index: number
          ) => {
            const toYearDataFees = toYearData[index];

            const fromYearFees = revenue_filters
              ? revenue_filters.reduce(
                  (acc: number, current: CheckboxRevenueOption) =>
                    acc +
                    (current.checked
                      ? current.value === "+"
                        ? fromYearData[index][current.key]
                        : -fromYearData[index][current.key]
                      : 0),
                  0
                )
              : isAdmin
              ? Revenue
              : accommodation_fare_adjusted_usd +
                total_fee_usd -
                host_channel_fee_usd;

            const toYearFees = toYearDataFees
              ? revenue_filters
                ? revenue_filters.reduce(
                    (acc: number, current: CheckboxRevenueOption) =>
                      acc +
                      (current.checked
                        ? current.value === "+"
                          ? toYearDataFees[current.key]
                          : -toYearDataFees[current.key]
                        : 0),
                    0
                  )
                : isAdmin
                ? toYearDataFees.Revenue
                : toYearDataFees.accommodation_fare_adjusted_usd +
                  toYearDataFees.total_fee_usd -
                  toYearDataFees.host_channel_fee_usd
              : 0;

            return {
              name: shortMonthsNames[index],
              [fromYear]: fromYearFees,
              [toYear]: toYearFees,
            };
          }
        )
      : null;

  useEffect(() => {
    if (data) {
      setMaxRevenueValue(findMaxNumericValue(data));
    }
  }, [data]);

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: GET_REVENUE_BY_MONTH_REQUEST,
        payload: filters
          ? {
              owners: filters.ownersToRequest,
              properties: filters.propertiesToRequest,
              beds: filters.bedroomsToRequest,
              year: [fromYear, toYear],
            }
          : { owners: [], properties: [], beds: [], year: [fromYear, toYear] },
      });
    }
  }, [dispatch, filters, fromYear, toYear]);

  return (
    <div className={classes.wrapper} id="revenue">
      <div className={classes.revenueBarContainer}>
        <div className={classes.revenueBarHeader}>
          <div className={classes.revenueBarHeaderLeft}>
            <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
              Earned Revenue:
            </h4>
            <div className={classes.periodBlock}>
              <PeriodDropdown
                period={fromYear}
                periodList={years}
                setPeriod={setFromYear}
                variant="standard"
              />
              <h4 className={classes.centerSelfAlign}> vs. </h4>
              <PeriodDropdown
                period={toYear}
                periodList={years}
                setPeriod={setToYear}
                variant="standard"
              />
            </div>
            {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
            {!isMobileView && <Legend legendData={revenueBarLegend} />}
          </div>
          {!isMobileView && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
        {isBarActive
          ? data && (
              <RevenueByMonthBarChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )
          : data && (
              <RevenueByMonthLineChart
                data={data}
                fromYear={fromYear}
                toYear={toYear}
                maxRevenueValue={maxRevenueValue}
              />
            )}
        {isMobileView && (
          <div className={classes.legendRow}>
            <Legend legendData={revenueBarLegend} />

            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
