import Box from "@mui/material/Box";

import { DynamicIndicator } from "../../../views/common/DynamicIndicator/DynamicIndicator";
import { IOccupancyHighlight } from "../../interfaces";
import classes from "./CircleOccupancyHighlight.module.scss";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";

export const OccupancyHighlight = ({
  label,
  rate,
  previousRate,
  reverse = false,
  showIndicator = false,
  highlightColor = "#00B48D",
}: IOccupancyHighlight) => {
  const isMobileView = useSelector(isMobileModeSelector);

  return (
    <div className={classes.hightlightWrapper}>
      {reverse ? label && <h3>{label}</h3> : null}
      <Box>
        <h3
          style={{ fontSize: isMobileView ? "24px" : "28px", margin: 0 }}
        >{`${(rate >= 1 ? rate : 100 * rate).toFixed(1)}%`}</h3>
      </Box>
      <div>
        {!reverse
          ? label && (
              <h3
                style={{
                  fontSize: "28px",
                  margin: 0,
                }}
              >
                {label}
              </h3>
            )
          : null}
        {showIndicator && (
          <DynamicIndicator previous={previousRate} current={rate} />
        )}
      </div>
    </div>
  );
};
