import { Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";

import classes from "./OccupancyRateVsMarket.module.scss";

import { isNumber } from "../../../../../utils/commonUtils";
import {
  L12M,
  L30D,
  N30D,
} from "../../../../../utils/constants/filterConstants";
import { OccupancyHighlight } from "../../../../common/CircleOccupancyHighlight/CircleOccupancyHighlight";
import { DynamicIndicator } from "../../../../common/DynamicIndicator/DynamicIndicator";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { BasicTooltip } from "../../../../common/BasicTooltip/BasicTooltip";
import { occupancyPieOwnerVsPaidReserv } from "../../../../../utils/constants/tooltipTexts";

interface IOccupancyRateVsMarketProps {
  or_current: number;
  mor_current: number;
  or_prev: number;
  mor_prev: number;
}

export const OccupancyRateVsMarket = ({
  or_current,
  mor_current,
  or_prev,
  mor_prev,
}: IOccupancyRateVsMarketProps) => {
  const isMobile = useSelector(isMobileModeSelector);

  return (
    <div className={classes.occupancyRateContainer}>
      <div className={classes.occupancyRateHeader}>
        <h4>Occupancy Rate vs Market</h4>
      </div>
      <div className={classes.occupancyRate}>
        <Box display="flex" alignItems="center" mt={4} mb={1}>
          <OccupancyHighlight rate={or_current} />
          <DynamicIndicator previous={or_prev} current={or_current} />
        </Box>

        <p>
          Occupancy Rate{" "}
          <BasicTooltip tooltip={occupancyPieOwnerVsPaidReserv} />
        </p>
      </div>
      {isMobile && <Divider />}
      <div className={classes.occupancyRate}>
        <Box display="flex" alignItems="center" mt={4} mb={1}>
          <OccupancyHighlight rate={mor_current} highlightColor="#EDC255" />
          <DynamicIndicator previous={mor_prev} current={mor_current} />
        </Box>

        <p>
          Market Occupancy Rate{" "}
          <BasicTooltip
            tooltip={
              "This is the Compared Market rate of occupied nights out of the total available nights."
            }
          />
        </p>
      </div>
    </div>
  );
};
