import { all, call, put, takeLatest } from "redux-saga/effects";
import { L30D, L3060D } from "../../../utils/constants/filterConstants";
import { CommonAPI } from "../../../api/commonAPI";
import { RatingAPI } from "../../../api/ratingAPI";
import {
  RESERVATIONS_HIGHLIGHTS_ERROR,
  RESERVATIONS_HIGHLIGHTS_REQUEST,
  RESERVATIONS_HIGHLIGHTS_SUCCESS,
} from "../../actions";

function* workerReservationsHighlights(action: {
  type: string;
  payload: { param: string };
}): Generator<any> {
  try {
    const params = action.payload;
    const data: any = yield all([
      // call(CommonAPI.getUniqueReservations, params),
      call(CommonAPI.getGuestsHosted, params),
      call(RatingAPI.getAverageStay, params),
      call(RatingAPI.getAverageBookingWindow, params),
    ]);
    const [
      uniqueReservations,
      guestsHosted,
      averageStay,
      averageBookingWindow,
    ] = data;

    const basePayload: { [key: string]: object } = {
      [params.param]: {
        uniqueReservations: uniqueReservations?.count,
        guestsHosted,
        averageStay,
        averageBookingWindow,
      },
    };

    if (params.param === L30D) {
      basePayload[L3060D] = {
        uniqueReservations: uniqueReservations?.last_30_60_count,
        guestsHosted,
        averageStay,
        averageBookingWindow,
      };
    }

    yield put({
      type: RESERVATIONS_HIGHLIGHTS_SUCCESS,
      payload: { ...basePayload },
    });
  } catch (error: any) {
    yield put({
      type: RESERVATIONS_HIGHLIGHTS_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherReservationsHighlights() {
  yield takeLatest(
    RESERVATIONS_HIGHLIGHTS_REQUEST,
    workerReservationsHighlights
  );
}
