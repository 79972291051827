export const AngelHostLogoMobile = () => (
  <svg
    width="143"
    height="20"
    viewBox="0 0 143 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7725_186866)">
      <path
        d="M6.30171 0.644531H7.76374L14.0738 15.0238H12.3629L10.7408 11.25H3.24015L1.59537 15.0238H0L6.30171 0.644531ZM10.1532 9.80369L7.02261 2.52439L3.85151 9.80369H10.1532Z"
        fill="#5D6293"
      />
      <path
        d="M18.7715 0.748047H20.2335L28.9509 12.1869V0.748047H30.4731V15.0241H29.223L20.2936 3.31696V15.0241H18.7715V0.748047Z"
        fill="#5D6293"
      />
      <path
        d="M35.7266 7.92976V7.88862C35.7266 3.96923 38.5167 0.506141 42.6688 0.506141C44.5612 0.427271 46.4099 1.10586 47.8288 2.40012L46.8406 3.62839C45.7005 2.53186 44.1863 1.94506 42.6283 1.99602C39.5644 1.99602 37.3684 4.66811 37.3684 7.84993V7.89107C37.3684 11.2934 39.4846 13.8205 42.845 13.8205C44.2966 13.8186 45.7061 13.317 46.8501 12.3951V8.84605H42.6289V7.39977H48.3365V13.0885C46.8066 14.4883 44.834 15.2638 42.7891 15.2692C38.4369 15.2692 35.7266 12.007 35.7266 7.92976Z"
        fill="#5D6293"
      />
      <path
        d="M53.8359 0.748047H63.8368V2.22196H55.398V7.0908H62.948V8.55796H55.398V13.5576H63.938V15.0248H53.8359V0.748047Z"
        fill="#5D6293"
      />
      <path
        d="M69.0488 0.748047H70.6109V13.5367H78.4008V15.0241H69.0488V0.748047Z"
        fill="#5D6293"
      />
      <path
        d="M83.2617 0.748047H84.8237V7.0908H92.8077V0.748047H94.3698V15.0241H92.8077V8.5991H84.8237V15.0241H83.2617V0.748047Z"
        fill="#5D6293"
      />
      <path
        d="M117.58 12.9438L118.548 11.7592C119.168 12.4068 119.907 12.9192 120.723 13.2659C121.538 13.6126 122.413 13.7865 123.294 13.7772C125.15 13.7772 126.379 12.7578 126.379 11.3532V11.3115C126.379 9.98249 125.711 9.24553 122.781 8.59824C119.597 7.9098 118.108 6.82831 118.108 4.46636V4.42521C118.108 2.18732 120.023 0.534077 122.654 0.534077C124.429 0.483784 126.16 1.106 127.52 2.28312L126.606 3.52981C125.497 2.52924 124.074 1.97856 122.601 1.98036C120.806 1.98036 119.657 2.99981 119.657 4.28765V4.3288C119.657 5.6719 120.365 6.39473 123.415 7.08318C126.466 7.77162 127.922 8.94215 127.922 11.1186V11.1579C127.922 13.6096 125.92 15.2002 123.196 15.2002C121.095 15.2234 119.067 14.4036 117.541 12.9137"
        fill="#5D6293"
      />
      <path
        d="M136.772 2.24223H132.129V0.748047H143.003V2.24223H138.36V15.0241H136.778L136.772 2.24223Z"
        fill="#5D6293"
      />
      <path
        d="M112.093 9.51227C111.86 10.1836 111.546 10.8221 111.159 11.413C110.818 11.9706 110.11 13.0454 109.009 14.6845L106.8 17.9486H106.7C106.126 17.0808 105.364 16 104.49 14.6845C103.382 13.0454 102.681 11.9713 102.334 11.413C101.949 10.827 101.633 10.196 101.393 9.53315C101.302 9.22587 101.242 8.90977 101.214 8.58984H99.7305C99.7651 9.06993 99.8548 9.54408 99.9977 10.0023C100.267 10.7919 100.635 11.542 101.092 12.2335C101.446 12.8052 102.167 13.8916 103.288 15.5596C104.178 16.8698 104.91 17.9533 105.485 18.8102C105.624 19.024 105.814 19.1988 106.035 19.3189C106.256 19.4391 106.503 19.5008 106.753 19.4987C107.002 19.5014 107.248 19.4399 107.469 19.3197C107.689 19.1995 107.876 19.0243 108.014 18.8102L110.217 15.5553C111.332 13.8972 112.053 12.8009 112.407 12.2427C112.858 11.544 113.227 10.7928 113.508 10.0048C113.647 9.54581 113.734 9.07186 113.768 8.5923H112.293C112.258 8.91193 112.196 9.22766 112.106 9.53561"
        fill="#00B48D"
      />
      <path
        d="M101.906 4.95968C102.402 4.09001 103.105 3.36492 103.948 2.8526C104.794 2.34325 105.756 2.07484 106.735 2.07484C107.714 2.07484 108.676 2.34325 109.522 2.8526C110.364 3.36647 111.066 4.09121 111.564 4.95968C111.915 5.59686 112.142 6.29868 112.231 7.02562H113.707C113.603 6.02622 113.293 5.06121 112.799 4.19509C112.177 3.1028 111.294 2.19255 110.235 1.55064C109.162 0.906019 107.943 0.566406 106.701 0.566406C105.459 0.566406 104.24 0.906019 103.166 1.55064C102.109 2.19429 101.227 3.10415 100.603 4.19509C100.108 5.06072 99.7981 6.02597 99.6953 7.02562H101.177C101.259 6.29695 101.486 5.59364 101.844 4.95968"
        fill="#5D6293"
      />
      <path
        d="M109.649 7.02057C109.474 6.36155 109.094 5.78006 108.567 5.36557C108.041 4.95108 107.397 4.72656 106.735 4.72656C106.072 4.72656 105.428 4.95108 104.902 5.36557C104.375 5.78006 103.996 6.36155 103.821 7.02057C103.749 7.28513 103.71 7.55828 103.707 7.83307C103.709 8.08134 103.738 8.32862 103.794 8.57003C103.958 9.24676 104.336 9.84758 104.869 10.2769C105.402 10.7063 106.058 10.9395 106.734 10.9395C107.41 10.9395 108.067 10.7063 108.6 10.2769C109.133 9.84758 109.511 9.24676 109.675 8.57003C109.728 8.32808 109.757 8.08114 109.762 7.83307C109.758 7.55828 109.72 7.28513 109.648 7.02057M107.82 8.97044C107.677 9.1193 107.506 9.23756 107.318 9.31844C107.131 9.39932 106.929 9.44125 106.726 9.44182C106.523 9.44239 106.321 9.40159 106.133 9.32176C105.945 9.24193 105.774 9.12464 105.63 8.97658L105.624 8.97044C105.513 8.8542 105.418 8.72203 105.343 8.57801C105.223 8.33732 105.163 8.06895 105.17 7.79798C105.177 7.52701 105.25 7.26229 105.383 7.02856C105.454 6.91343 105.536 6.80709 105.63 6.71166C105.77 6.5568 105.939 6.43431 106.128 6.35235C106.317 6.27039 106.52 6.23084 106.725 6.23633C106.93 6.23209 107.133 6.2722 107.321 6.35409C107.51 6.43597 107.68 6.55779 107.82 6.71166C107.909 6.80743 107.988 6.9138 108.053 7.02856C108.202 7.27146 108.278 7.55386 108.273 7.84105C108.276 8.09784 108.216 8.35122 108.1 8.57801C108.024 8.72322 107.929 8.85744 107.819 8.9772"
        fill="#00B48D"
      />
    </g>
    <defs>
      <clipPath id="clip0_7725_186866">
        <rect
          width="143"
          height="19"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
