export const GlossaryIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33672 4.39349C2.85331 3.38589 4.85242 2.80078 7.00078 2.80078C8.84853 2.80078 10.5859 3.23361 12.0008 3.99579C13.4157 3.23361 15.153 2.80078 17.0008 2.80078C19.1503 2.80078 21.1483 3.38593 22.6648 4.39349C22.9996 4.61589 23.2008 4.99111 23.2008 5.393V19.8374C23.2008 20.2797 22.9576 20.686 22.5678 20.895C22.1781 21.104 21.7051 21.0817 21.3367 20.837C20.2577 20.1201 18.7335 19.6452 17.0008 19.6452C15.2691 19.6452 13.7438 20.1201 12.6648 20.837C12.2625 21.1043 11.7391 21.1043 11.3367 20.837C10.2578 20.1201 8.73242 19.6452 7.00078 19.6452C5.26914 19.6452 3.74381 20.1201 2.66484 20.837C2.29651 21.0817 1.82344 21.104 1.43371 20.895C1.04399 20.686 0.800781 20.2797 0.800781 19.8374V5.393C0.800781 4.99111 1.00197 4.61589 1.33672 4.39349ZM10.8008 6.07594C9.77742 5.53921 8.46261 5.20078 7.00078 5.20078C5.53895 5.20078 4.22414 5.53921 3.20078 6.07594V17.9015C4.3526 17.4775 5.64706 17.2452 7.00078 17.2452C8.3545 17.2452 9.64896 17.4775 10.8008 17.9015V6.07594ZM13.2008 17.9015C14.3526 17.4775 15.6471 17.2452 17.0008 17.2452C18.3551 17.2452 19.6493 17.4775 20.8008 17.9014V6.07591C19.7776 5.53918 18.4635 5.20078 17.0008 5.20078C15.5389 5.20078 14.2241 5.53921 13.2008 6.07594V17.9015Z"
      fill="#5D6293"
    />
  </svg>
);
