import { DynamicIndicator } from "../../../views/common/DynamicIndicator/DynamicIndicator";
import classes from "./BasicHighlight.module.scss";
import { IHighlight } from "../../interfaces";
import { BasicTooltip } from "../BasicTooltip/BasicTooltip";
import { StarIcon } from "../../../assets/icons/StarIcon";
import { toPrettyNumber } from "../../../utils/commonUtils";

export const Highlight = ({
  changeValueColor = false,
  className,
  label,
  prependSymbol = "",
  rating = false,
  reverse = false,
  showDynamicIndicator = true,
  tooltip,
  value,
  previousValue,
  isDecimal,
}: IHighlight) => {
  const preparedNumber = isDecimal
    ? Number(value).toFixed(1)
    : Number(value).toFixed(0);
  const number = prependSymbol ? toPrettyNumber(value) : preparedNumber;
  const highlightValue = (
    <div className={classes.highlightValueContainer}>
      <h2
        className={`${classes.highlightValue} ${
          changeValueColor ? classes.green : ""
        }`}
      >
        {prependSymbol +
          (Number.isNaN(+number) ? "NA" : (+number).toLocaleString("en-EN"))}
      </h2>
      {rating && <StarIcon />}
      {showDynamicIndicator && (
        <div className={classes.dynamicIndicator}>
          {value && previousValue ? (
            <DynamicIndicator previous={previousValue} current={value} />
          ) : null}
        </div>
      )}
    </div>
  );
  const highlightLabel = (
    <div className={classes.highlightLabelWithTooltip}>
      <h3 className={classes.highlightLabel}>{label}</h3>
      {tooltip && <BasicTooltip tooltip={tooltip} />}
    </div>
  );

  return (
    <div className={`${classes.highlight} ${className}`}>
      {reverse ? (
        <>
          {highlightLabel}
          {highlightValue}
        </>
      ) : (
        <>
          {highlightValue}
          {highlightLabel}
        </>
      )}
    </div>
  );
};
