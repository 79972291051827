import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { IMenuItem, IMenuList } from "../../interfaces";
import classes from "./MenuList.module.scss";
import { LogoutIcon } from "../../../assets/icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_USER_REQUEST } from "../../../redux/actions";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import { MOBILE_BLOCK_IDS } from "../../../utils/constants/pdfGeneratorConstants";
import { Document, Page, Image, pdf } from "@react-pdf/renderer";
import { performanceHeaderSelector } from "../../../redux/selectors/performanceDataSelector";
import { IPerformanceHeader } from "../../../api/performanceDataAPI";

export const MenuList = ({ activePath, list, navigate }: IMenuList) => {
  const isMobileView = useSelector(isMobileModeSelector);
  const header: IPerformanceHeader = useSelector(performanceHeaderSelector);

  const navigateDOM = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({
      type: LOGOUT_USER_REQUEST,
      payload: { navigateDOM },
    });
  };

  const handleGeneratePDF = () => {
    const promises = MOBILE_BLOCK_IDS.map((id) => {
      const input = document.getElementById(id);

      if (input) {
        const inputWidth = input.scrollWidth;
        const inputHeight = input.scrollHeight;

        return html2canvas(input, {
          width: inputWidth,
          windowHeight: inputHeight,
          scrollX: 0,
          scrollY: 0,
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          return { imgData, imgWidth, imgHeight };
        });
      }

      return null;
    });

    Promise.all(promises).then((results) => {
      const pdfDoc = (
        <Document>
          <Page size="A5">
            {results.map((result, index) =>
              result ? (
                <Image
                  src={result.imgData}
                  key={index}
                  style={{ width: "auto", height: result.imgHeight }}
                />
              ) : null
            )}
          </Page>
        </Document>
      );

      const asPdfBlob = pdf(pdfDoc).toBlob();

      asPdfBlob.then((blob: string | Blob) => {
        saveAs(blob, "performance_report.pdf");
      });
    });
  };

  return (
    <List>
      {list.map(({ id, label, icon, url }: IMenuItem) => {
        const isActive = activePath === url || activePath.includes(url);
        return (
          <ListItem className={classes.menuItem} disablePadding key={id}>
            <ListItemButton
              className={isActive ? classes.activeMenuButton : ""}
              onClick={
                id === "download"
                  ? () => handleGeneratePDF()
                  : id === "glossary"
                  ? () => {
                      if (header) window.location.href = header.glossary_link;
                    }
                  : () => navigate(url)
              }
              onTouchEnd={
                id === "download"
                  ? () => handleGeneratePDF()
                  : id === "glossary"
                  ? () => {
                      if (header) window.location.href = header.glossary_link;
                    }
                  : () => navigate(url)
              }
            >
              <ListItemIcon
                className={
                  isActive ? classes.activeMenuIcon : classes.menuItemIcon
                }
              >
                {icon}
              </ListItemIcon>
              <ListItemText
                className={
                  isActive ? classes.activeItemLabel : classes.menuItemLabel
                }
                primary={label}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
      {isMobileView ? (
        <ListItem
          className={classes.menuItem}
          disablePadding
          key="logout_key_id"
        >
          <ListItemButton onClick={handleLogout} onTouchEnd={handleLogout}>
            <ListItemIcon className={classes.menuItemIcon}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText className={classes.menuItemLabel} primary="Logout" />
          </ListItemButton>
        </ListItem>
      ) : null}
    </List>
  );
};
