import {
  createBrowserRouter,
  matchRoutes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ErrorPage } from "../views/pages/ErrorPage/ErrorPage";
import { NotFoundPage } from "../views/pages/404ErrorPage/404ErrorPage";
import { LoginPage } from "../views/pages/LoginPage/LoginPage";
import { PerfomancePage } from "../views/pages/PerfomancePage/PerfomancePage";

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <Navigate to="/perfomance" replace />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/perfomance",
    element: <PerfomancePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export const router = createBrowserRouter(routes, { basename: "/" });

export const useCurrentPath = () => {
  const location = useLocation();
  const [{ pathname }]: any = matchRoutes(routes, location);

  return pathname;
};
