import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { isMobileModeSelector } from "../../../../../../../redux/selectors/appStatusSelector";
import { useEffect, useRef, useState } from "react";
import classes from "./RevenueByMonthBarChart.module.scss";
import { toPrettyNumber } from "../../../../../../../utils/commonUtils";

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    maxYAxisValue,
    isMobileView,
    leftTooltipHeight,
    leftTooltipWidth,
    arrowWidth,
    cartesianGridWidth,
    data,
    barWidth,
    barGapWidth,
    leftTooltipRef,
    rightTooltipHeight,
    rightTooltipWidth,
    rightTooltipRef,
  } = props;
  const leftMonths = isMobileView ? ["Jan", "Feb", "Mar"] : ["Jan", "Feb"];
  const rightMonths = isMobileView
    ? ["Oct", "Nov", "Dec"]
    : ["Oct", "Nov", "Dec"];

  if (active && payload && payload.length) {
    const leftDataPoint = payload[0].payload;
    const rightDataPoint = payload[1].payload;
    const leftName = payload[0].name;
    const rightName = payload[1].name;
    const isLeftMonth = leftMonths.includes(leftDataPoint.name);
    const isRightMonth = rightMonths.includes(rightDataPoint.name);

    return (
      <>
        <div
          className={`${classes.tooltip} ${
            !isLeftMonth ? classes.leftTooltip : classes.rightTooltip
          }`}
          style={{
            bottom: !isLeftMonth
              ? (viewBox?.height * leftDataPoint[leftName]) / maxYAxisValue -
                leftTooltipHeight -
                20
              : (viewBox?.height * rightDataPoint[rightName]) / maxYAxisValue -
                20,
            left: !isLeftMonth
              ? -leftTooltipWidth -
                arrowWidth +
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2
              : arrowWidth +
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2,
          }}
          ref={leftTooltipRef}
        >
          <div className={`${classes.box} ${classes.green}`}></div>
          <p>{`${leftName}: $${toPrettyNumber(
            Number(leftDataPoint[leftName])
          )}`}</p>
        </div>

        <div
          className={`${classes.tooltip} ${
            !isRightMonth ? classes.rightTooltip : classes.leftTooltip
          }`}
          style={{
            bottom: !isRightMonth
              ? (viewBox?.height * rightDataPoint[rightName]) / maxYAxisValue -
                rightTooltipHeight -
                20
              : (viewBox?.height * leftDataPoint[leftName]) / maxYAxisValue -
                20,
            left: !isRightMonth
              ? cartesianGridWidth / data.length +
                arrowWidth -
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2
              : cartesianGridWidth / data.length +
                arrowWidth -
                (cartesianGridWidth / data.length -
                  2 * barWidth +
                  rightTooltipWidth * 2 +
                  barWidth * 7 -
                  barGapWidth) /
                  2,
          }}
          ref={rightTooltipRef}
        >
          <div className={`${classes.box} ${classes.blue}`}></div>
          <p>{`${rightName}: $${toPrettyNumber(
            Number(rightDataPoint[rightName])
          )}`}</p>
        </div>
      </>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        $ {payload.value.toLocaleString("en-EN")}
      </text>
    </g>
  );
};

type RevenueByMonthBarChartProps = {
  data: object[];
  fromYear: string;
  toYear: string;
  maxRevenueValue: number;
};

export const RevenueByMonthBarChart = ({
  data,
  fromYear,
  toYear,
  maxRevenueValue,
}: RevenueByMonthBarChartProps) => {
  const isMobileView = useSelector(isMobileModeSelector);

  const barRadius = isMobileView ? 2 : 4;
  const barWidth = isMobileView ? 6 : 16;
  const barGapWidth = isMobileView ? 2 : 8;
  const arrowWidth = isMobileView ? 6 : 10;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const leftTooltipRef = useRef<any>(null);
  const rightTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [maxYAxisValue, setMaxYAxisValue] = useState(
    Math.ceil(maxRevenueValue - (maxRevenueValue % 500) + 500)
  );
  const [leftTooltipWidth, setLeftTooltipWidth] = useState(0);
  const [rightTooltipWidth, setRightTooltipWidth] = useState(0);
  const [leftTooltipHeight, setLeftTooltipHeight] = useState(0);
  const [rightTooltipHeight, setRightTooltipHeight] = useState(0);

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const leftTooltip = leftTooltipRef?.current;
    const rightTooltip = rightTooltipRef?.current;
    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );
    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    leftTooltip &&
      setLeftTooltipWidth(leftTooltip.getBoundingClientRect().width);
    leftTooltip &&
      setLeftTooltipHeight(leftTooltip.getBoundingClientRect().height);
    rightTooltip &&
      setRightTooltipWidth(rightTooltip.getBoundingClientRect().width);
    rightTooltip &&
      setRightTooltipHeight(rightTooltip.getBoundingClientRect().height);
  }, [activeTooltipIndex]);

  useEffect(() => {
    if (maxRevenueValue) {
      setMaxYAxisValue(
        Math.ceil(maxRevenueValue - (maxRevenueValue % 500) + 500)
      );
    }
  }, [maxRevenueValue]);

  return (
    <>
      {data && (
        <ResponsiveContainer
          height="80%"
          width="99%"
          ref={responsiveContainerRef}
        >
          <BarChart
            data={data}
            barGap={barGapWidth}
            onMouseMove={({ activeTooltipIndex }) =>
              setActiveTooltipIndex(activeTooltipIndex)
            }
            onMouseLeave={() => setActiveTooltipIndex(undefined)}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid
              stroke="#B4B7CF"
              strokeDasharray="2.33 4.66"
              strokeWidth="0.5"
              vertical={false}
              ref={cartesianGridRef}
            />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              interval={isMobileView ? 0 : undefined}
              tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={<CustomYAxisTick isMobileView={isMobileView} />}
              domain={[0, maxYAxisValue]}
            />
            <Tooltip
              position={{
                x: activeTooltipIndex
                  ? cartesianGridLeftOffset +
                    (activeTooltipIndex * cartesianGridWidth) / data.length
                  : cartesianGridLeftOffset,
                y: cartesianGridHeight - 15,
              }}
              content={
                <CustomTooltip
                  maxYAxisValue={maxYAxisValue}
                  isMobileView={isMobileView}
                  leftTooltipHeight={leftTooltipHeight}
                  leftTooltipWidth={leftTooltipWidth}
                  arrowWidth={arrowWidth}
                  cartesianGridWidth={cartesianGridWidth}
                  data={data}
                  barWidth={barWidth}
                  barGapWidth={barGapWidth}
                  leftTooltipRef={leftTooltipRef}
                  rightTooltipHeight={rightTooltipHeight}
                  rightTooltipWidth={rightTooltipWidth}
                  rightTooltipRef={rightTooltipRef}
                />
              }
              cursor={false}
              allowEscapeViewBox={{ x: true, y: true }}
            />
            <Bar
              barSize={barWidth}
              dataKey={fromYear}
              isAnimationActive={false}
              fill="#BDE0D9"
              radius={[barRadius, barRadius, 0, 0]}
            />
            <Bar
              barSize={barWidth}
              dataKey={toYear}
              isAnimationActive={false}
              fill="#009978"
              radius={[barRadius, barRadius, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
