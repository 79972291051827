import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider, IconButton } from "@mui/material";
import classes from "./OccupancyRateBarLineCharts.module.scss";
import { OCCUPANCY_BY_YEAR_REQUEST } from "../../../redux/actions";
import { yearlyOccupancySelector } from "../../../redux/selectors/occupancyRateSelector";
import {
  getCurrentYearString,
  getPreviousYearString,
  toDoublePercent,
} from "../../../utils/commonUtils";
import {
  shortMonthsNames,
  yearsList,
} from "../../../utils/constants/periodLists";
import { IYearlyOccupancy } from "../../interfaces";
import { Legend } from "../ChartLegend/ChartLegend";
import { PeriodDropdown } from "../PeriodDropdown/PeriodDropdown";
import { OccupancyRateBar } from "./components/OccupancyRateBar/OccupancyRateBar";
import { OccupancyRateLineChart } from "./components/OccupancyRateLineChart/OccupancyRateLineChart";
import { BarIcon } from "../../../assets/icons/BarIcon";
import { LineIcon } from "../../../assets/icons/LineIcon";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import { performanceDataFilterSelector } from "../../../redux/selectors/performanceDataSelector";

export const OccupancyRateBarLineCharts = (props: {
  bothCharts: boolean;
  page: "performance" | "occupancy";
}) => {
  const dispatch = useDispatch();
  const { bothCharts, page } = props;
  const [fromYear, setFromYear] = useState(getPreviousYearString());
  const [toYear, setToYear] = useState(getCurrentYearString());
  const [chartType, setChartType] = useState("bar");

  const filters = useSelector(performanceDataFilterSelector);

  const isMobileView = useSelector(isMobileModeSelector);

  const isBarActive = chartType === "bar";

  const fromYearData = useSelector(yearlyOccupancySelector(fromYear));
  const toYearData = useSelector(yearlyOccupancySelector(toYear));

  const years = yearsList();

  const occupancyBarLegend = [
    {
      label: fromYear,
      color: "#C1C3D7",
      legendType: "circle",
    },
    {
      label: toYear,
      color: "#5D6293",
      legendType: "circle",
    },
  ];

  const data =
    fromYearData && toYearData
      ? fromYearData.map((monthData: IYearlyOccupancy, index: number) => {
          const fromYearTotal =
            monthData.occupancy_rate_direct +
            monthData.occupancy_rate_guest +
            monthData.occupancy_rate_guestyical +
            monthData.occupancy_rate_ical +
            monthData.occupancy_rate_portal;

          const toYearTotal =
            toYearData[index].occupancy_rate_direct +
            toYearData[index].occupancy_rate_guest +
            toYearData[index].occupancy_rate_guestyical +
            toYearData[index].occupancy_rate_ical +
            toYearData[index].occupancy_rate_portal;
          return {
            name: shortMonthsNames[index],
            [fromYear]: toDoublePercent(fromYearTotal),
            [toYear]: toDoublePercent(toYearTotal),
          };
        })
      : null;

  useEffect(() => {
    if (fromYear !== toYear) {
      dispatch({
        type: OCCUPANCY_BY_YEAR_REQUEST,
        payload: filters
          ? {
              owners: filters.ownersToRequest,
              properties: filters.propertiesToRequest,
              beds: filters.bedroomsToRequest,
              year: [fromYear, toYear],
            }
          : {
              owners: [],
              properties: [],
              beds: [],
              year: [fromYear, toYear],
            },
      });
    }
  }, [dispatch, filters, fromYear, toYear]);

  return (
    <div
      className={
        bothCharts
          ? classes.occupancyChartsContainer
          : `${classes.occupancyChartsContainer} ${classes.occupancyBarContainer}`
      }
      id="occupancy_rate"
    >
      <div className={classes.occupancyChartsHeader}>
        <h4 style={{ fontSize: isMobileView ? "16px" : "24px" }}>
          Occupancy Rate:
        </h4>
        <div className={classes.periodBlock}>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={fromYear}
            periodList={years}
            setPeriod={setFromYear}
            variant="standard"
          />
          <h4 className={classes.centerSelfAlign}> vs. </h4>
          <PeriodDropdown
            className={classes.standardDropdown}
            period={toYear}
            periodList={years}
            setPeriod={setToYear}
            variant="standard"
          />
        </div>
        {!isMobileView ? <Divider flexItem orientation="vertical" /> : null}
        {!isMobileView ? (
          <div className={classes.legendRow}>
            <Legend legendData={occupancyBarLegend} />
            {bothCharts && (
              <div className={classes.switcher}>
                <IconButton
                  classes={{ root: classes.button }}
                  className={isBarActive ? classes.activeButton : ""}
                  color={isBarActive ? "secondary" : "inherit"}
                  aria-label="change chart type"
                  onClick={() => setChartType("bar")}
                >
                  <BarIcon />
                </IconButton>
                <IconButton
                  classes={{ root: classes.button }}
                  className={!isBarActive ? classes.activeButton : ""}
                  color={!isBarActive ? "secondary" : "inherit"}
                  aria-label="change chart type"
                  onClick={() => setChartType("line")}
                >
                  <LineIcon />
                </IconButton>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {isBarActive && (
        <OccupancyRateBar
          data={data}
          fromYear={fromYear}
          toYear={toYear}
          page={page}
        />
      )}
      {bothCharts && !isBarActive && (
        <OccupancyRateLineChart
          data={data}
          fromYear={fromYear}
          toYear={toYear}
          page={page}
        />
      )}
      {isMobileView ? (
        <div className={classes.legendRow}>
          <Legend legendData={occupancyBarLegend} />
          {bothCharts && (
            <div className={classes.switcher}>
              <IconButton
                classes={{ root: classes.button }}
                className={isBarActive ? classes.activeButton : ""}
                color={isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("bar")}
              >
                <BarIcon />
              </IconButton>
              <IconButton
                classes={{ root: classes.button }}
                className={!isBarActive ? classes.activeButton : ""}
                color={!isBarActive ? "secondary" : "inherit"}
                aria-label="change chart type"
                onClick={() => setChartType("line")}
              >
                <LineIcon />
              </IconButton>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
