import { call, put, takeLatest } from "redux-saga/effects";
import { OccupancyRateAPI } from "../../../api/occupancyRateAPI";
import {
  RESERVATIONS_VS_BLOCKS_OCCUPANCY_ERROR,
  RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST,
  RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS,
} from "../../actions";

function* workerReservationsVsBlocks(action: {
  type: string;
  payload: { owners: string[]; properties: string[]; beds: string[] };
}): Generator<any> {
  try {
    const param = action.payload;
    const data: any = yield call(OccupancyRateAPI.getOwnerBlocksRate, param);

    yield put({
      type: RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS,
      payload: { occupancyRates: data },
    });
  } catch (error: any) {
    yield put({
      type: RESERVATIONS_VS_BLOCKS_OCCUPANCY_ERROR,
      payload: { message: error.message },
    });
  }
}

export function* watcherReservationsVsBlocks() {
  yield takeLatest(
    RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST,
    workerReservationsVsBlocks
  );
}
