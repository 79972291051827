import {
  createErrorSelector,
  createLoadingSelector,
} from "../../utils/reduxUtils";
import {
  OCCUPANCY_BY_YEAR,
  MARKET_OCCUPANCY,
  GENERAL_OCCUPANCY,
  DAILY_OCCUPANCY,
} from "../actions";

export const marketOccupancySelector = (period: string) => (state: any) =>
  state.occupancy.marketOccupancy?.[period];

export const generalOccupancySelector = (period: string) => (state: any) =>
  state.occupancy.generalOccupancy?.[period];

export const occupancyRatesSelector = (state: any) =>
  state.occupancy.occupancyRates;

export const yearlyOccupancySelector = (year: string) => (state: any) =>
  state.occupancy.yearlyOccupancy?.[year];

export const yearlyOccupancyGeneralSelector = (state: any) =>
  state.occupancy.yearlyOccupancy;

export const yearlyMarketOccupancySelector = (year: string) => (state: any) =>
  state.occupancy.yearlyMarketOccupancy?.[year];

export const dailyOccupancySelector = (period: string) => (state: any) =>
  state.occupancy.dailyOccupancy[period];

export const occupancyYearlyLoadingSelector = createLoadingSelector([
  OCCUPANCY_BY_YEAR,
]);
export const occupancyYearlyErrorSelector = createErrorSelector([
  OCCUPANCY_BY_YEAR,
]);

export const marketOccupancyLoadingSelector = createLoadingSelector([
  MARKET_OCCUPANCY,
]);
export const marketOccupancyErrorSelector = createErrorSelector([
  MARKET_OCCUPANCY,
]);

export const generalOccupancyLoadingSelector = createLoadingSelector([
  GENERAL_OCCUPANCY,
]);
export const generalOccupancyErrorSelector = createErrorSelector([
  GENERAL_OCCUPANCY,
]);

export const dailyOccupancyLoadingSelector = createLoadingSelector([
  DAILY_OCCUPANCY,
]);
export const dailyOccupancyErrorSelector = createErrorSelector([
  DAILY_OCCUPANCY,
]);
