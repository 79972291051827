export const WINDOW_RESIZE = "WINDOW_RESIZE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const USER_DATA = "USER_DATA";
export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_ERROR = "USER_DATA_ERROR";

export const USER_LOGO = "USER_LOGO";
export const USER_LOGO_REQUEST = "USER_LOGO_REQUEST";
export const USER_LOGO_SUCCESS = "USER_LOGO_SUCCESS";
export const USER_LOGO_ERROR = "USER_LOGO_ERROR";

export const PR_DATA = "PR-DATA";
export const PR_DATA_REQUEST = "PR_DATA_REQUEST";
export const PR_DATA_SUCCESS = "PR_DATA_SUCCESS";
export const PR_DATA_ERROR = "PR_DATA_ERROR";

export const PR_DATA_FILTER = "PR_DATA_FILTER";
export const PR_REVENUE_FILTER = "PR_REVENUE_FILTER";

export const PR_HEADER = "PR-PR_HEADER";
export const PR_HEADER_REQUEST = "PR_HEADER_REQUEST";
export const PR_HEADER_SUCCESS = "PR_HEADER_SUCCESS";
export const PR_HEADER_ERROR = "PR_HEADER_ERROR";

export const SEND_CONTACTS = "SEND_CONTACTS";
export const SEND_CONTACTS_REQUEST = "SEND_CONTACTS_REQUEST";
export const SEND_CONTACTS_SUCCESS = "SEND_CONTACTS_SUCCESS";
export const SEND_CONTACTS_ERROR = "SEND_CONTACTS_ERROR";

export const PERFOMANCE_HIGHLIGHTS = "PERFOMANCE_HIGHLIGHTS";
export const PERFOMANCE_HIGHLIGHTS_REQUEST = "PERFOMANCE_HIGHLIGHTS_REQUEST";
export const PERFOMANCE_HIGHLIGHTS_SUCCESS = "PERFOMANCE_HIGHLIGHTS_SUCCESS";
export const PERFOMANCE_HIGHLIGHTS_ERROR = "PERFOMANCE_HIGHLIGHTS_ERROR";

export const MARKET_OCCUPANCY = "MARKET_OCCUPANCY";
export const MARKET_OCCUPANCY_REQUEST = "MARKET_OCCUPANCY_REQUEST";
export const MARKET_OCCUPANCY_SUCCESS = "MARKET_OCCUPANCY_SUCCESS";
export const MARKET_OCCUPANCY_ERROR = "MARKET_OCCUPANCY_ERROR";

export const OCCUPANCY_BY_YEAR = "OCCUPANCY_BY_YEAR";
export const OCCUPANCY_BY_YEAR_REQUEST = "OCCUPANCY_BY_YEAR_REQUEST";
export const OCCUPANCY_BY_YEAR_SUCCESS = "OCCUPANCY_BY_YEAR_SUCCESS";
export const OCCUPANCY_BY_YEAR_ERROR = "OCCUPANCY_BY_YEAR_ERROR";

export const MONTHLY_OCCUPANCY_RATE = "MONTHLY_OCCUPANCY_RATE";
export const MONTHLY_OCCUPANCY_RATE_REQUEST = "MONTHLY_OCCUPANCY_RATE_REQUEST";
export const MONTHLY_OCCUPANCY_RATE_SUCCESS = "MONTHLY_OCCUPANCY_RATE_SUCCESS";
export const MONTHLY_OCCUPANCY_RATE_ERROR = "MONTHLY_OCCUPANCY_RATE_ERROR";

export const GENERAL_OCCUPANCY = "GENERAL_OCCUPANCY";
export const GENERAL_OCCUPANCY_REQUEST = "GENERAL_OCCUPANCY_REQUEST";
export const GENERAL_OCCUPANCY_SUCCESS = "GENERAL_OCCUPANCY_SUCCESS";
export const GENERAL_OCCUPANCY_ERROR = "GENERAL_OCCUPANCY_ERROR";

export const RESERVATIONS_VS_BLOCKS_OCCUPANCY =
  "RESERVATIONS_VS_BLOCKS_OCCUPANCY";
export const RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST =
  "RESERVATIONS_VS_BLOCKS_OCCUPANCY_REQUEST";
export const RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS =
  "RESERVATIONS_VS_BLOCKS_OCCUPANCY_SUCCESS";
export const RESERVATIONS_VS_BLOCKS_OCCUPANCY_ERROR =
  "RESERVATIONS_VS_BLOCKS_OCCUPANCY_ERROR";

export const DAILY_OCCUPANCY = "DAILY_OCCUPANCY";
export const DAILY_OCCUPANCY_REQUEST = "DAILY_OCCUPANCY_REQUEST";
export const DAILY_OCCUPANCY_SUCCESS = "DAILY_OCCUPANCY_SUCCESS";
export const DAILY_OCCUPANCY_ERROR = "DAILY_OCCUPANCY_ERROR";

export const GET_REVENUE = "GET_REVENUE";
export const GET_REVENUE_REQUEST = "GET_REVENUE_REQUEST";
export const GET_REVENUE_SUCCESS = "GET_REVENUE_SUCCESS";
export const GET_REVENUE_ERROR = "GET_REVENUE_ERROR";

export const GET_PER_NIGHT_REVENUE = "GET_PER_NIGHT_REVENUE";
export const GET_PER_NIGHT_REVENUE_REQUEST = "GET_PER_NIGHT_REVENUE_REQUEST";
export const GET_PER_NIGHT_REVENUE_SUCCESS = "GET_PER_NIGHT_REVENUE_SUCCESS";
export const GET_PER_NIGHT_REVENUE_ERROR = "GET_PER_NIGHT_REVENUE_ERROR";

export const GET_YEARLY_REVENUE = "GET_YEARLY_REVENUE";
export const GET_YEARLY_REVENUE_REQUEST = "GET_YEARLY_REVENUE_REQUEST";
export const GET_YEARLY_REVENUE_SUCCESS = "GET_YEARLY_REVENUE_SUCCESS";
export const GET_YEARLY_REVENUE_ERROR = "GET_YEARLY_REVENUE_ERROR";

export const GET_REVENUE_BY_MONTH = "GET_REVENUE_BY_MONTH";
export const GET_REVENUE_BY_MONTH_REQUEST = "GET_REVENUE_BY_MONTH_REQUEST";
export const GET_REVENUE_BY_MONTH_SUCCESS = "GET_REVENUE_BY_MONTH_SUCCESS";
export const GET_REVENUE_BY_MONTH_ERROR = "GET_REVENUE_BY_MONTH_ERROR";

export const GET_RESERVATIONS_BY_CHANNEL = "GET_RESERVATIONS_BY_CHANNEL";
export const GET_RESERVATIONS_BY_CHANNEL_REQUEST =
  "GET_RESERVATIONS_BY_CHANNEL_REQUEST";
export const GET_RESERVATIONS_BY_CHANNEL_SUCCESS =
  "GET_RESERVATIONS_BY_CHANNEL_SUCCESS";
export const GET_RESERVATIONS_BY_CHANNEL_ERROR =
  "GET_RESERVATIONS_BY_CHANNEL_ERROR";

export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";

export const RESERVATIONS_HIGHLIGHTS = "RESERVATIONS_HIGHLIGHTS";
export const RESERVATIONS_HIGHLIGHTS_REQUEST =
  "RESERVATIONS_HIGHLIGHTS_REQUEST";
export const RESERVATIONS_HIGHLIGHTS_SUCCESS =
  "RESERVATIONS_HIGHLIGHTS_SUCCESS";
export const RESERVATIONS_HIGHLIGHTS_ERROR = "RESERVATIONS_HIGHLIGHTS_ERROR";

export const GET_RESERVATIONS_DATA = "GET_RESERVATIONS_DATA";
export const GET_RESERVATIONS_DATA_REQUEST = "GET_RESERVATIONS_DATA_REQUEST";
export const GET_RESERVATIONS_DATA_SUCCESS = "GET_RESERVATIONS_DATA_SUCCESS";
export const GET_RESERVATIONS_DATA_ERROR = "GET_RESERVATIONS_DATA_ERROR";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR";

// export const DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT";
// export const DOWNLOAD_DOCUMENT_REQUEST = "DOWNLOAD_DOCUMENT_REQUEST";
// export const DOWNLOAD_DOCUMENT_SUCCESS = "DOWNLOAD_DOCUMENT_SUCCESS";
// export const DOWNLOAD_DOCUMENT_ERROR = "DOWNLOAD_DOCUMENT_ERROR";

export const SAVE_ACCOUNT_SETTINGS = "SAVE_ACCOUNT_SETTINGS";
export const SAVE_ACCOUNT_SETTINGS_REQUEST = "SAVE_ACCOUNT_SETTINGS_REQUEST";
export const SAVE_ACCOUNT_SETTINGS_SUCCESS = "SAVE_ACCOUNT_SETTINGS_SUCCESS";
export const SAVE_ACCOUNT_SETTINGS_ERROR = "SAVE_ACCOUNT_SETTINGS_ERROR";

export const RESERVATIONS_INDICATORS = "RESERVATIONS_INDICATORS";
export const RESERVATIONS_INDICATORS_REQUEST =
  "RESERVATIONS_INDICATORS_REQUEST";
export const RESERVATIONS_INDICATORS_SUCCESS =
  "RESERVATIONS_INDICATORS_SUCCESS";
export const RESERVATIONS_INDICATORS_ERROR = "SAVE_ACCOUNT_SETTINGS_ERROR";

export const RESERVATIONS_PREVIOUS_INDICATORS =
  "RESERVATIONS_PREVIOUS_INDICATORS";
export const RESERVATIONS_PREVIOUS_INDICATORS_REQUEST =
  "RESERVATIONS_PREVIOUS_INDICATORS_REQUEST";
export const RESERVATIONS_PREVIOUS_INDICATORS_SUCCESS =
  "RESERVATIONS_PREVIOUS_INDICATORS_SUCCESS";
export const RESERVATIONS_PREVIOUS_INDICATORS_ERROR =
  "RESERVATIONS_PREVIOUS_INDICATORS_ERROR";
