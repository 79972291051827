export const SortingAscIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.59583 5.28711C2.12869 5.28711 1.75 5.6658 1.75 6.13294C1.75 6.60008 2.12869 6.97878 2.59583 6.97878H6.09583C6.56297 6.97878 6.94167 6.60008 6.94167 6.13294C6.94167 5.6658 6.56297 5.28711 6.09583 5.28711H2.59583ZM2.59583 12.834C2.12869 12.834 1.75 13.2127 1.75 13.6798C1.75 14.147 2.12869 14.5257 2.59583 14.5257H10.7625C11.2296 14.5257 11.6083 14.147 11.6083 13.6798C11.6083 13.2127 11.2296 12.834 10.7625 12.834H2.59583ZM2.59583 20.4629C2.12869 20.4629 1.75 20.8416 1.75 21.3087C1.75 21.7759 2.12869 22.1546 2.59583 22.1546H15.4292C15.8963 22.1546 16.275 21.7759 16.275 21.3087C16.275 20.8416 15.8963 20.4629 15.4292 20.4629H2.59583Z"
      fill="#00B48D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1756 22.1074C20.6428 22.1074 21.0215 21.7287 21.0215 21.2616V8.22705L24.263 11.6696C24.5832 12.0097 25.1185 12.0258 25.4586 11.7055C25.7987 11.3853 25.8148 10.85 25.4946 10.5099L20.7915 5.51508C20.6316 5.34536 20.4089 5.24912 20.1758 5.24909C19.9426 5.24906 19.7198 5.34524 19.56 5.51492L14.8146 10.552C14.4943 10.892 14.5102 11.4273 14.8502 11.7477C15.1902 12.068 15.7256 12.052 16.0459 11.712L19.3298 8.22624V21.2616C19.3298 21.7287 19.7085 22.1074 20.1756 22.1074Z"
      fill="#00B48D"
    />
  </svg>
);
