import { Divider, Grid } from "@mui/material";
import { FullPageContainer } from "../../common/FullPageContainer/FullPageContainer";
import { RevenueTable } from "../RevenuePage/components/RevenueTable/RevenueTable";
import { OccupancyRatePie } from "../OccupancyPage/components/OccupancyRatePie/OccupancyRatePie";
import { PerfomanceHighlights } from "./components/PerfomanceHighlights";
import { MonthlyOccupancyVsMarket } from "../OccupancyPage/components/MonthlyOccupancyVsMarket/MonthlyOccupancyVsMarket";
import { OccupancyRateBarLineCharts } from "../../common/OccupancyRateCharts/OccupancyRateBarLineCharts";
import { RevenueByMonth } from "../RevenuePage/components/RevenueByMonth/RevenueByMonth";
import { OwnerOccupancy } from "./components/OwnerOccupancy/OwnerOccupancy";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import { useSelector } from "react-redux";
import { FiltersContainer } from "./components/FiltersContainer/FiltersContainer";
import { useMemo } from "react";
import { IPerformanceDataElement } from "../../../api/performanceDataAPI";
import {
  performanceDataFilterSelector,
  performanceDataSelector,
} from "../../../redux/selectors/performanceDataSelector";
import { AverageDailyRateByMonth } from "../RevenuePage/components/AverageDailyRate/AverageDailyRate";
import { RevenuePerNightByMonth } from "../RevenuePage/components/RevenuePerAvailableNight/RevenuePerNight";

export const PerfomancePage = () => {
  const isMobileView = useSelector(isMobileModeSelector);
  const prData = useSelector(performanceDataSelector);
  const filters = useSelector(performanceDataFilterSelector);

  const aggregatedData: IPerformanceDataElement[] = useMemo(
    () =>
      prData
        ? prData.filter((property: IPerformanceDataElement) => {
            const current_owner = property.property_id.substring(1, 4);

            if (
              filters &&
              (!!+property.is_listed
                ? filters.listed.yes
                : filters.listed.no) &&
              (!filters.owners.length ||
                filters.owners.includes(current_owner)) &&
              (!filters.properties.length ||
                filters.properties.includes(property.property_id)) &&
              (!filters.bedrooms.length ||
                filters.bedrooms.includes(property.bedrooms))
            ) {
              return true;
            } else if (!!+property.is_listed && !filters) {
              return true;
            }

            return false;
          })
        : [],
    [filters, prData]
  );

  return (
    <FullPageContainer>
      <>
        <FiltersContainer count={aggregatedData && aggregatedData.length} />
        <PerfomanceHighlights />
        {isMobileView && (
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
        )}
        <Grid
          container
          sx={{ justifyContent: "space-between" }}
          direction={isMobileView ? "column" : "row"}
          flexWrap="nowrap"
          gap="24px"
          id="occupancy"
        >
          <RevenueTable />
          <OccupancyRatePie />
        </Grid>
        <MonthlyOccupancyVsMarket />
        <OccupancyRateBarLineCharts page="occupancy" bothCharts />
        <RevenueByMonth />
        <OwnerOccupancy />
        <AverageDailyRateByMonth />
        <RevenuePerNightByMonth />
      </>
    </FullPageContainer>
  );
};
