export const SortingDescIcon = () => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.59583 22.6536C2.12869 22.6536 1.75 22.2749 1.75 21.8077C1.75 21.3406 2.12869 20.9619 2.59583 20.9619H6.09583C6.56297 20.9619 6.94167 21.3406 6.94167 21.8077C6.94167 22.2749 6.56297 22.6536 6.09583 22.6536H2.59583ZM2.59583 15.1067C2.12869 15.1067 1.75 14.728 1.75 14.2609C1.75 13.7937 2.12869 13.415 2.59583 13.415H10.7625C11.2296 13.415 11.6083 13.7937 11.6083 14.2609C11.6083 14.728 11.2296 15.1067 10.7625 15.1067H2.59583ZM2.59583 7.47779C2.12869 7.47779 1.75 7.09909 1.75 6.63195C1.75 6.16481 2.12869 5.78612 2.59583 5.78612H15.4292C15.8963 5.78612 16.275 6.16481 16.275 6.63195C16.275 7.09909 15.8963 7.47779 15.4292 7.47779H2.59583Z"
      fill="#00B48D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1756 5.75C20.6428 5.75 21.0215 6.12869 21.0215 6.59583V19.6304L24.263 16.1879C24.5832 15.8478 25.1185 15.8317 25.4586 16.1519C25.7987 16.4721 25.8148 17.0075 25.4946 17.3476L20.7915 22.3423C20.6316 22.5121 20.4089 22.6083 20.1758 22.6083C19.9426 22.6084 19.7198 22.5122 19.56 22.3425L14.8146 17.3054C14.4943 16.9654 14.5102 16.4301 14.8502 16.1098C15.1902 15.7894 15.7256 15.8054 16.0459 16.1454L19.3298 19.6312V6.59583C19.3298 6.12869 19.7085 5.75 20.1756 5.75Z"
      fill="#00B48D"
    />
  </svg>
);
