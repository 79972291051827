import {
  createErrorSelector,
  createLoadingSelector,
} from "../../utils/reduxUtils";
import { PR_DATA, PR_HEADER } from "../actions";

export const performanceDataSelector = (state: any) => state.prData.data;

export const performanceDataLoadingSelector = createLoadingSelector([PR_DATA]);
export const performanceDataErrorSelector = createErrorSelector([PR_DATA]);

export const performanceHeaderSelector = (state: any) => state.prData.header;

export const performanceHeaderLoadingSelector = createLoadingSelector([
  PR_HEADER,
]);
export const performanceHeaderErrorSelector = createErrorSelector([PR_HEADER]);

export const performanceDataFilterSelector = (state: any) =>
  state.prData.filters;

export const performanceRevenueFilterSelector = (state: any) =>
  state.prData.revenue_filters;
