import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { IOccupancyRateChart } from "../../../../interfaces";
import { useSelector } from "react-redux";
import { isMobileModeSelector } from "../../../../../redux/selectors/appStatusSelector";
import { useEffect, useRef, useState } from "react";
import classes from "./OccupancyRateBar.module.scss";

const CustomTooltip = (props: any) => {
  const {
    active,
    payload,
    viewBox,
    isMobileView,
    leftTooltipHeight,
    leftTooltipWidth,
    arrowWidth,
    cartesianGridWidth,
    data,
    barWidth,
    barGapWidth,
    leftTooltipRef,
    rightTooltipHeight,
    rightTooltipRef,
  } = props;
  const leftMonths = isMobileView ? ["Jan", "Feb", "Mar"] : ["Jan"];
  const rightMonths = isMobileView ? ["Oct", "Nov", "Dec"] : ["Nov", "Dec"];

  if (active && payload && payload.length) {
    const leftDataPoint = payload[0].payload;
    const rightDataPoint = payload[1].payload;
    const leftName = payload[0].name;
    const rightName = payload[1].name;
    const isLeftMonth = leftMonths.includes(leftDataPoint.name);
    const isRightMonth = rightMonths.includes(rightDataPoint.name);

    return (
      <>
        <div
          className={`${classes.tooltip} ${
            !isLeftMonth ? classes.leftTooltip : classes.rightTooltip
          }`}
          style={{
            bottom: !isLeftMonth
              ? (viewBox?.height * leftDataPoint[leftName]) / 100 -
                leftTooltipHeight -
                20
              : (viewBox?.height * rightDataPoint[rightName]) / 100 - 20,
            left: !isLeftMonth
              ? -leftTooltipWidth -
                arrowWidth +
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2
              : barWidth +
                arrowWidth +
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2,
          }}
          ref={leftTooltipRef}
        >
          <div className={`${classes.box} ${classes.green}`}></div>
          <p>{`${leftName}: ${leftDataPoint[leftName]}%`}</p>
        </div>

        <div
          className={`${classes.tooltip} ${
            !isRightMonth ? classes.rightTooltip : classes.leftTooltip
          }`}
          style={{
            bottom: !isRightMonth
              ? (viewBox?.height * rightDataPoint[rightName]) / 100 -
                rightTooltipHeight -
                20
              : (viewBox?.height * leftDataPoint[leftName]) / 100 - 20,
            left: !isRightMonth
              ? cartesianGridWidth / data.length +
                arrowWidth -
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2
              : -leftTooltipWidth +
                cartesianGridWidth / data.length +
                arrowWidth -
                barWidth * 3 -
                (cartesianGridWidth / data.length -
                  2 * barWidth -
                  barGapWidth) /
                  2,
          }}
          ref={rightTooltipRef}
        >
          <div className={`${classes.box} ${classes.blue}`}></div>
          <p>{`${rightName}: ${rightDataPoint[rightName]}%`}</p>
        </div>
      </>
    );
  }

  return null;
};

const CustomYAxisTick = (props: any) => {
  const { x, y, payload, isMobileView } = props;
  return (
    <g transform={`translate(${isMobileView ? 0 : 20},${y})`}>
      <text x={0} y={0} textAnchor="start" fontSize={12} fill="#9798A5">
        {payload.value === 0 ? payload.value : `${payload.value}%`}
      </text>
    </g>
  );
};

export const OccupancyRateBar = ({
  data,
  fromYear,
  toYear,
  page,
}: IOccupancyRateChart) => {
  const isMobileView = useSelector(isMobileModeSelector);
  const barRadius = isMobileView ? 2 : 4;
  const barWidth = isMobileView ? 6 : 16;
  const barGapWidth = isMobileView ? 2 : 8;
  const arrowWidth = isMobileView ? 6 : 10;

  const responsiveContainerRef = useRef<any>(null);
  const cartesianGridRef = useRef<any>(null);
  const leftTooltipRef = useRef<any>(null);
  const rightTooltipRef = useRef<any>(null);

  const [activeTooltipIndex, setActiveTooltipIndex] = useState<
    number | undefined
  >();
  const [cartesianGridWidth, setCartesianGridWidth] = useState(0);
  const [cartesianGridHeight, setCartesianGridHeight] = useState(0);
  const [cartesianGridLeftOffset, setCartesianGridLeftOffset] = useState(0);
  const [leftTooltipWidth, setLeftTooltipWidth] = useState(0);
  const [rightTooltipWidth, setRightTooltipWidth] = useState(0);
  const [leftTooltipHeight, setLeftTooltipHeight] = useState(0);
  const [rightTooltipHeight, setRightTooltipHeight] = useState(0);

  useEffect(() => {
    const responsiveContainer = responsiveContainerRef?.current?.current;
    const cartesianGrid =
      cartesianGridRef?.current?._reactInternals?.child?.stateNode;
    const leftTooltip = leftTooltipRef?.current;
    const rightTooltip = rightTooltipRef?.current;

    cartesianGrid &&
      responsiveContainer &&
      setCartesianGridLeftOffset(
        cartesianGrid.getBoundingClientRect().left -
          responsiveContainer.getBoundingClientRect().left
      );

    cartesianGrid &&
      setCartesianGridWidth(cartesianGrid.getBoundingClientRect().width);
    cartesianGrid &&
      setCartesianGridHeight(cartesianGrid.getBoundingClientRect().height);
    leftTooltip &&
      setLeftTooltipWidth(leftTooltip.getBoundingClientRect().width);
    leftTooltip &&
      setLeftTooltipHeight(leftTooltip.getBoundingClientRect().height);
    rightTooltip &&
      setRightTooltipWidth(rightTooltip.getBoundingClientRect().width);
    rightTooltip &&
      setRightTooltipHeight(rightTooltip.getBoundingClientRect().height);
  }, [activeTooltipIndex]);

  return (
    <>
      {data && (
        <ResponsiveContainer
          height={isMobileView ? 280 : "80%"}
          width="100%"
          ref={responsiveContainerRef}
        >
          <BarChart
            data={data}
            barGap={barGapWidth}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
            onMouseMove={({ activeTooltipIndex }) =>
              setActiveTooltipIndex(activeTooltipIndex)
            }
            onMouseLeave={() => setActiveTooltipIndex(undefined)}
          >
            <CartesianGrid
              stroke="#B4B7CF"
              strokeDasharray="2.33 4.66"
              strokeWidth="0.5"
              vertical={false}
              id="grid"
              ref={cartesianGridRef}
            />
            <XAxis
              axisLine={false}
              dataKey="name"
              tickLine={false}
              interval={isMobileView ? 0 : undefined}
              tick={{ fill: "#9798A5", fontSize: isMobileView ? 10 : 14 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={[0, 100]}
              unit="%"
              tick={<CustomYAxisTick isMobileView={isMobileView} />}
              width={isMobileView ? 24 : 60}
            />
            <Tooltip
              position={{
                x: activeTooltipIndex
                  ? cartesianGridLeftOffset +
                    (activeTooltipIndex * cartesianGridWidth) / data.length
                  : cartesianGridLeftOffset,
                y: cartesianGridHeight - 15,
              }}
              content={
                <CustomTooltip
                  isMobileView={isMobileView}
                  leftTooltipHeight={leftTooltipHeight}
                  leftTooltipWidth={leftTooltipWidth}
                  arrowWidth={arrowWidth}
                  cartesianGridWidth={cartesianGridWidth}
                  data={data}
                  barWidth={barWidth}
                  barGapWidth={barGapWidth}
                  leftTooltipRef={leftTooltipRef}
                  rightTooltipHeight={rightTooltipHeight}
                  rightTooltipRef={rightTooltipRef}
                />
              }
              cursor={false}
              allowEscapeViewBox={{ x: true, y: true }}
            />
            <Bar
              barSize={barWidth}
              dataKey={fromYear}
              isAnimationActive={false}
              fill="#C1C3D7"
              radius={[barRadius, barRadius, 0, 0]}
            />
            <Bar
              barSize={barWidth}
              dataKey={toYear}
              isAnimationActive={false}
              fill="#5D6293"
              radius={[barRadius, barRadius, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
