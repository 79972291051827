import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import classes from "./FullPageContainer.module.scss";
import {
  PR_DATA_REQUEST,
  PR_HEADER_REQUEST,
  USER_DATA_REQUEST,
  USER_LOGO_REQUEST,
} from "../../../redux/actions";
import { isMobileModeSelector } from "../../../redux/selectors/appStatusSelector";
import {
  userDataSelector,
  userListingsSelector,
  userLogoSelector,
} from "../../../redux/selectors/userDataSelector";
import { useCurrentPath } from "../../../utils/router";
import { IFullPageView, IListing, IView } from "../../interfaces";
import { DesktopHeader } from "../DesktopHeader/DesktopHeader";
import { MobileHeader } from "../MobileHeader/MobileHeader";
import { getAuthToken } from "../../../utils/apiUtils";

const DesktopView = ({
  activePath,
  children,
  className,
  listing,
  logo,
  navigate,
  userName,
}: IView) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className={classes.desktopPageContainer}>
      <DesktopHeader
        handleMenu={setOpenMenu}
        listing={listing}
        open={openMenu}
        userName={userName}
      />
      <div className={`${classes.contentWrapper} ${className}`}>{children}</div>
    </div>
  );
};

const MobileView = ({
  activePath,
  children,
  className,
  listing,
  navigate,
  userName,
}: IView) => {
  return (
    <div className={classes.mobilePageContainer}>
      <MobileHeader
        activePath={activePath}
        listing={listing}
        navigate={navigate}
        userName={userName}
      />
      <div className={`${classes.mobileContentWrapper} ${className}`}>
        {children}
      </div>
      {/* TODO: Update mobile view styles */}
    </div>
  );
};

export const FullPageContainer = ({ children, className }: IFullPageView) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileView = useSelector(isMobileModeSelector);
  const token = getAuthToken();
  const userData = useSelector(userDataSelector);
  const logo = useSelector(userLogoSelector);
  const listings = useSelector(userListingsSelector);

  const activePath = useCurrentPath();

  const listing = listings?.find(({ is_listed }: IListing) => is_listed);

  useEffect(() => {
    if (token) {
      dispatch({
        type: PR_DATA_REQUEST,
      });

      dispatch({
        type: PR_HEADER_REQUEST,
      });
    }
  }, [dispatch, token]);

  const props = {
    activePath,
    children,
    className,
    listing: listing?.nickname,
    logo,
    navigate,
    userName: userData?.name,
  };

  return (
    <>
      {!token && <Navigate to="/login" replace />}
      {/* {listings && userData && ( */}
      <>
        {isMobileView ? <MobileView {...props} /> : <DesktopView {...props} />}
      </>
      {/* )} */}
    </>
  );
};
