import { requestHandler, getServiceURL } from "../utils/apiUtils";

export class PerfomanceAPI {
  static get getAvarageDailyRatesServiceName() {
    return "GET_AVERAGE_DAILY_RATES";
  }

  static async getAvarageDailyRates(params?: {
    owners: string[];
    properties: string[];
    beds: string[];
  }): Promise<number> {
    const url = getServiceURL(PerfomanceAPI.getAvarageDailyRatesServiceName);
    const response = await requestHandler(url, {
      ...params,
      properties: params?.properties.map((el) => el.split("-")[0]),
    });
    return response?.data?.data;
  }
}
